<div id="view-new-league" class="post-wrapper">
  <header>
      <h1>Post a New Event</h1>
      <div class="card error centered" *ngIf="errored">Oops! It looks like there are some fields highlighted in red below that need your attention.</div>
      <div class="masthead">
        Are you planning a game day, tournament, or an exciting weekend-long HvZ event? Let everyone know about your upcoming event by filling out the details below!
      </div>
  </header>
  <form #formCreateGroup="ngForm" class="padding-bottom-20">
    <span class="field-label">Basic Info</span>
    <div class="field-row">
      <label>
        <span>Host</span>
        <select *ngIf="authService.user" [(ngModel)]="form.club" name="host">
          <option [value]="null" selected>Self-Hosted</option>
          <ng-container *ngFor="let club of authService.user.clubs">
            <option *ngIf="authService.user._id == club.admin" [value]="club._id">{{club.name}}</option>
          </ng-container>

        </select>
      </label>
      <label>
        <span>Format</span>
        <select [(ngModel)]="form.format" name="format">
          <option value="Event">General Event</option>
          <option value="Outdoor">Outdoor Battle</option>
          <option value="Indoor">Indoor Battle</option>
          <option value="LARP">Live Action Roleplay</option>
          <option value="HvZ">Humans VS Zombies</option>
          <option value="Scrimmage">Competitive Scrimmage</option>
          <option value="Tournament">Competitive Tournament</option>
        </select>
      </label>
    </div>
    <div class="field-row">
      <label [ngClass]="{
            dirty: form.title,
            invalid: errors.title
      }">
        <span>Event Title<b>*</b><em class="error" *ngIf="errors && errors.title">{{errors.title}}</em></span>
        <input [(ngModel)]="form.title" name="name" type="text" required/>
      </label>
    </div>
    <div class="field-row">
      <label class="half" [ngClass]="{
            dirty: form.start,
            invalid: errors.start
      }">
        <span>Start Time<b>*</b><em class="error" *ngIf="errors && errors.start">{{errors.start}}</em></span>
        <input [(ngModel)]="form.start" name="start" type="datetime-local" required/>
      </label>
      <label class="half" [ngClass]="{
            dirty: form.end,
            invalid: errors.end
      }">
        <span>End Time<b>*</b><em class="error" *ngIf="errors && errors.end">{{errors.end}}</em></span>
        <input [(ngModel)]="form.end" name="end" type="datetime-local" required/>
      </label>
    </div>
    <div class="field-row">
      <label [ngClass]="{
            dirty: form.description,
            invalid: errors.description
      }">
        <span>Event Description<b>*</b><em class="error" *ngIf="errors && errors.description">{{errors.description}}</em></span>
        <textarea [(ngModel)]="form.description" name="description"></textarea>
      </label>
    </div>
    <div class="field-row">
      <label [ngClass]="{
            dirty: form.url,
            invalid: errors.url
      }">
        <span>External URL</span>
        <input [(ngModel)]="form.url" name="url" type="text"/>
      </label>
    </div>
    <hr />
    <span class="field-label">Location</span>
    <div class="field-row">
      <label [ngClass]="{
            dirty: form.location,
            invalid: errors.location
      }">
        <span>Address / Location<b>*</b><em class="error" *ngIf="errors && errors.location">{{errors.location}}</em></span>
        <input [(ngModel)]="form.location" name="location" type="text" required/>
      </label>
    </div>
    <hr/>
    <span class="field-label">Safety</span>
    <div class="field-row">
      <label class="setting-toggle">
        <dl class="details">
          <dt class="title">Face Masks</dt>
          <dd>Attendees Must Wear Masks</dd>
        </dl>
        <div class="switch" [ngClass]="{active: form.masks}">
          <b></b>
        </div>
        <input [(ngModel)]="form.masks" type="checkbox" name="safety-masks" />
      </label>
      <label class="setting-toggle">
        <dl class="details">
          <dt class="title">COVID Vaccination</dt>
          <dd>Vaccination / Boosters Required</dd>
        </dl>
        <div class="switch" [ngClass]="{active: form.vaccine}">
          <b></b>
        </div>
        <input [(ngModel)]="form.vaccine" type="checkbox" name="safety-masks" />
      </label>
    </div>
    <hr/>
    <span class="field-label">Rules</span>
    <div class="field-row">
      <label>
        <span>FPS Cap<b>*</b></span>
        <input [(ngModel)]="form.cap" name="cap" type="number" required/>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>Full Rules</span>
        <textarea [(ngModel)]="form.rules" name="rules"></textarea>
      </label>
    </div>
    <span class="button submit" [ngClass]="{disabled:formCreateGroup.invalid, busy: busy}" (click)="submit()"><span class="text">Post Event</span><span class="spinner"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span></span>
  </form>
</div>
