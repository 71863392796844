import { Injectable } from '@angular/core';
import {ApplicationService} from "../app.service";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public queue: any = [];
  public timer = 0;
  public interval: any = null;

  public duration = 5;

  constructor(public appService: ApplicationService) {
    if (this.appService.isBrowser) {
      this.interval = setInterval(() => {
        if (this.queue.length) {
          if (this.queue[0].timer > 0) {
            this.queue[0].timer--;
          } else {
            this.dismissToast();
          }
        }
      }, 1000);
    }
  }

  addToast(type: string, title:string, text: string, photo: any = null, data: any = null) {
    this.queue.push({
      type,
      title,
      text,
      photo,
      data,
      timer: this.duration
    });
  }

  dismissToast(){
    this.queue.shift();
  }
}
