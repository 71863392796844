<div id="view-new-league">
  <header>
    <div class="wrapper narrow">
      <h1>Register a Blaster</h1>
      <div class="masthead">
        TBD
      </div>
    </div>
  </header>
  <form class="wrapper narrow" #formCreateGroup="ngForm">
    <span class="field-label">Basic Info</span>
    <div class="field-row">
      <label>
        <span>Blaster Name<b>*</b></span>
        <input [(ngModel)]="form.name" name="name" type="text" required/>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>Brand or Designer<b>*</b></span>
        <input [(ngModel)]="form.manufacturer" name="manufacturer" type="text" required/>
      </label>
      <label>
        <span>SKU / Model<b>*</b></span>
        <input [(ngModel)]="form.sku" name="sku" type="text" required/>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>URL<b>*</b></span>
        <input [(ngModel)]="form.url" name="url" type="text" required/>
      </label>
      <label>
        <span>Purchase Link</span>
        <input [(ngModel)]="form.purchase" name="purchase" type="text"/>
      </label>
    </div>
    <span class="field-label">Additional Info</span>
    <div class="field-row">
      <label>
        <span>Description</span>
        <textarea [(ngModel)]="form.description" name="description"></textarea>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>Released</span>
        <input [(ngModel)]="form.released" name="released" type="date"/>
      </label>
      <label>
        <span>Assembly</span>
        <select [(ngModel)]="form.assembly" name="assembly">
          <option>3D Printed</option>
          <option>Injection Molded</option>
          <option>Machined</option>
        </select>
      </label>
    </div>
    <span class="button submit" [ngClass]="{disabled:formCreateGroup.invalid, busy: busy}" (click)="submit()">Register Blaster</span>
  </form>
</div>
