import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private http: HttpClient) { }

  getPost(id: string) {
    return this.http.get(environment.endpoint + '/posts/' + id);
  }

  getPosts(types: any[]|null = [], tags: any[]|null = [] , limit: number = 4, skip: number = 0) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    if (types && types.length) {
      filter += '&types=' + types.join(',');
    }

    if (tags && tags.length) {
      filter += '&tags=' + tags.join(',')
    }

    return this.http.get(environment.endpoint + '/posts' + filter);
  }

  getPostReactions(id: string) {
    return this.http.get(environment.endpoint + '/posts/' + id + '/reactions');
  }

  createPost(type: string, data:any){
    return this.http.post(environment.endpoint + '/posts/' + type, data);
  }

  editPost(id:string, data: any) {
    return this.http.put(environment.endpoint + '/posts/' + id, data);
  }

  runPostAction(id:string, action: string, data:any) {
    return this.http.post(environment.endpoint + '/posts/' + id + '/' + action, data);
  }
}
