import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private http: HttpClient) { }

  getGame(id: string) {
    return this.http.get(environment.endpoint + '/games/' + id);
  }

  getGames(sort: any, filters: any, limit: number = 4, skip: number = 0, expanded = false) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    if (sort) {
      filter += '&sort=' + sort;
    }

    if (filters) {
      filter += '&filters=' + JSON.stringify(filters);
    }

    return this.http.get(environment.endpoint + '/games' + filter);
  }

  getFeaturedGames(types: string[], limit: number = 4) {
    let filter = '?limit=' + limit + '&type=' + types.join(',') + '&featured=true';

    return this.http.get(environment.endpoint + '/games' + filter);
  }

  createGame(data: any){
    return this.http.post(environment.endpoint + '/games', data);
  }

  editGame(id:string, data: any){
    return this.http.put(environment.endpoint + '/games/' + id, data);
  }

  runGameAction(id:string, action: string, data:any) {
    return this.http.post(environment.endpoint + '/games/' + id + '/' + action, data);
  }
}
