import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private eventSource = new BehaviorSubject('auth:init');
  public authEvent = this.eventSource.asObservable();

  public jwt: any = null;
  public user: any = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) {
  }

  reauthorize() {
    if (isPlatformBrowser(this.platformId)) {
      const jwt = localStorage.getItem('jwt');
      if (jwt) {
        this.setJWT(jwt);
      }
    }
  }

  hasRole(role: string){
    return this.user && this.user.roles.length && this.user.roles.indexOf(role) > -1;
  }

  refresh(callback?: any) {
    this.http.get(environment.endpoint + '/auth/me').subscribe((res: any) => {
      if (res.success && res.user) {
        this.user = res.user;
        this.eventSource.next('auth:success');
      } else {
        localStorage.removeItem('jwt');
        this.eventSource.next('auth:failure');
      }
      if (callback) {
        callback(res.user);
      }
    }, () => {
      this.eventSource.next('auth:failure');
      localStorage.removeItem('jwt');
      if (callback) {
        callback(false);
      }
    });
  }

  signOut(){
    if (isPlatformBrowser(this.platformId)) {
      this.user = null;
      this.jwt = null;
      localStorage.removeItem('jwt');
    }
  }

  signUp(name: string, email: string, handle: string) {
    return this.http.post(environment.endpoint + '/auth/signup', {
      name,
      email,
      handle
    });
  }

  requestToken(email: string) {
    return this.http.post(environment.endpoint + '/auth/login', {
      email
    });
  }

  verifyToken(email: string, token: any) {
    return this.http.post(environment.endpoint + '/auth/verify', {
      email,
      token
    });
  }

  redeemGift(code: string) {
    return this.http.post(environment.endpoint + '/redeem', {
      code
    });
  }

  submitContact(data: any) {
    return this.http.post(environment.endpoint + '/contact', data);
  }

  setJWT(token: string) {
    this.jwt = token;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('jwt', token);
    }

    this.refresh();
  }
}
