import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class ConversationsService {

  constructor(private http: HttpClient) { }

  getConversation(key: string) {
    return this.http.get(environment.endpoint + '/conversations/' + key);
  }

  getConversations(limit: number = 20, skip: number = 0) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    return this.http.get(environment.endpoint + '/conversations' + filter);
  }

  getMessages(key:string, sort: any, limit: number = 20, skip: number = 0) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    if (sort) {
      filter += '&sort=' + sort;
    }

    return this.http.get(environment.endpoint + '/messages/' + key + filter);
  }

  createMessage(key: any, data: any){
    return this.http.post(environment.endpoint + '/messages/' + key, data);
  }

  runConversationAction(id:string, action: string, data:any) {
    return this.http.post(environment.endpoint + '/conversations/' + id + '/' + action, data);
  }

  runMessageAction(id:string, action: string, data:any) {
    return this.http.post(environment.endpoint + '/messages/' + id + '/' + action, data);
  }
}
