import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})

export class GetInitialsPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return '';
    }

    let words = value.replace(/\W/g, '').split(' ');
    return words.length > 1 ? words.map(n => n[0]).join('').substring(0, 2) : words[0].substring(0, 2);
  }
}
