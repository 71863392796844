import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NgZone, NO_ERRORS_SCHEMA, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import 'zone.js/plugins/task-tracking';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TokenInterceptor} from './token.interceptor';
import {DebounceChangeDirective} from './directives/debouncechange.directive';
import {FileUploadComponent} from './components/fileupload/fileupload.component';
import {StateAbbreviationPipe} from './pipes/state-abbreviation.pipe';
import {PluralPipe} from './pipes/plural.pipe';
import {MarkdownModule} from 'ngx-markdown';
import {TimeFormat} from "./directives/time.directive";
import {ShelfComponent} from "./components/shelf/shelf.component";
import {SanitizeUrlPipe} from "./pipes/sanitize.pipe";
import {MatchComponent} from "./views/match/match.component";
import {NewClubComponent} from "./views/new-club/new-club.component";
import {NewTeamComponent} from "./views/new-team/new-team.component";
import {TrimPipe} from "./pipes/trim.pipe";
import {NewGameComponent} from "./views/new-game/new-game.component";
import {RankPipe} from "./pipes/rank.pipe";
import {PluckPipe} from "./pipes/pluck.pipe";
import {ReplacePipe} from "./pipes/replace.pipe";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {GetInitialsPipe} from "./pipes/initials.pipe";
import {TermsComponent} from "./views/terms/terms.component";
import {GamelistComponent} from "./components/gamelist/gamelist.component";
import {MapComponent} from "./components/map/map.component";
import {TimeAgoPipe} from "./pipes/timeago.pipe";
import {TruncatePipe} from "./pipes/truncate.pipe";
import {JoinPipe} from "./pipes/join.pipe";
import {VODsComponent} from "./views/vods/vods.component";
import {ShortNumberPipe} from "./pipes/shortnumber.pipe";
import {GeolocationComponent} from "./components/geolocation/geolocation.component";
import {NotfoundComponent} from "./views/notfound/notfound.component";
import {NewBlasterComponent} from "./views/new-blaster/new-blaster.component";
import {NewBuildComponent} from "./views/new-build/new-build.component";
import {OrdinalPipe} from "./pipes/ordinal.pipe";
import {CardBuildComponent} from "./components/card-build/card-build.component";
import {CardClubComponent} from "./components/card-club/card-club.component";
import {CardProductComponent} from "./components/card-product/card-product.component";
import {SplitPipe} from "./pipes/split.pipe";
import {ConversationComponent} from "./components/conversation/conversation.component";
import {CardPostComponent} from "./components/card-post/card-post.component";
import {HandleComponent} from "./components/handle/handle.component";
import {TempPipe} from "./pipes/temp.pipe";
import {MentionsPipe} from "./pipes/mentions.pipe";
import {HesitateDirective} from "./directives/hesitate.directive";
import {ExploreComponent} from "./views/explore/explore.component";
import {ConvertPipe} from "./pipes/convert.pipe";
import {ForEachPipe} from "./pipes/foreach.pipe";
import {SingularPipe} from "./pipes/singular.pipe";
import {PhotoComponent} from "./components/photo/photo.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    CardBuildComponent,
    CardClubComponent,
    CardPostComponent,
    CardProductComponent,
    ConversationComponent,
    FileUploadComponent,
    GamelistComponent,
    PhotoComponent,
    TimeAgoPipe,
    TrimPipe,
    TempPipe,
    TruncatePipe,
    PluckPipe,
    PluralPipe,
    SanitizeUrlPipe,
    GetInitialsPipe,
    JoinPipe,
    MentionsPipe,
    OrdinalPipe,
    ShortNumberPipe,
    SplitPipe,
    SingularPipe,
    StateAbbreviationPipe,
    RankPipe,
    ReplacePipe,
    ForEachPipe,
    HandleComponent,
    ConvertPipe,
    MapComponent,
    TimeFormat,
    ShelfComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports: [
    RouterModule,
    CardBuildComponent,
    CardClubComponent,
    CardPostComponent,
    CardProductComponent,
    ConversationComponent,
    FileUploadComponent,
    GamelistComponent,
    PhotoComponent,
    TimeAgoPipe,
    TrimPipe,
    TempPipe,
    TruncatePipe,
    PluckPipe,
    PluralPipe,
    SanitizeUrlPipe,
    GetInitialsPipe,
    JoinPipe,
    MentionsPipe,
    OrdinalPipe,
    ShortNumberPipe,
    SplitPipe,
    SingularPipe,
    StateAbbreviationPipe,
    RankPipe,
    ReplacePipe,
    ForEachPipe,
    HandleComponent,
    ConvertPipe,
    MapComponent,
    TimeFormat,
    ShelfComponent,
  ]
})
export class SharedModule{
  static forRoot() {
    return {
      ngModule: SharedModule
    }
  }
}

@NgModule({
	declarations: [
		AppComponent,
		DebounceChangeDirective,
    ExploreComponent,
    HesitateDirective,
		MatchComponent,
		NewGameComponent,
		NewClubComponent,
		NewTeamComponent,
    NewBlasterComponent,
    NewBuildComponent,
    NotfoundComponent,
		TermsComponent,
		VODsComponent,
		GeolocationComponent
	],
  imports: [
    SharedModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule {
  constructor() {
  }
}
