<div id="view-new-league" class="post-wrapper">
  <header>
      <h1>Found a Club</h1>
      <div class="card error centered" *ngIf="errored">Oops! It looks like there are some fields highlighted in red below that need your attention.</div>
      <div class="masthead">
        Clubs are a great way to help players connect with like-minded or nearby players and keep tabs on nearby Events. Once your Club is good to go, players will be able to see it on the Club list and map!
      </div>
  </header>
  <form #formCreateGroup="ngForm" class="padding-bottom-20">
    <span class="field-label">Basic Info</span>
    <div class="field-row">
      <label [ngClass]="{
            dirty: form.name,
            invalid: errors.name
      }">
        <span>Club Name<b>*</b><em class="error" *ngIf="errors && errors.name">{{errors.name}}</em></span>
        <input [(ngModel)]="form.name" name="name" type="text" required/>
      </label>
      <label class="half" [ngClass]="{
            dirty: form.tag,
            invalid: errors.tag
      }">
        <span>Club Tag<b>*</b><em class="error" *ngIf="errors && errors.tag">{{errors.tag}}</em></span>
        <input [(ngModel)]="form.tag" name="tag" type="text" required/>
      </label>
      <label class="half" [ngClass]="{
            dirty: true,
            invalid: errors.focus
      }">
        <span>Focus<b>*</b></span>
        <select [(ngModel)]="form.focus" name="format" required>
          <option>Select a Format</option>
          <ng-container *ngIf="focuses">
            <option *ngFor="let focus of focuses" value="{{focus}}">{{focus}}</option>
          </ng-container>
        </select>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>About Your Club</span>
        <textarea [(ngModel)]="form.description" name="description"></textarea>
      </label>
    </div>
    <div class="field-row">
      <label [ngClass]="{
            dirty: form.url,
            invalid: errors.url
      }">
        <span>Public Website or Facebook Group<b>*</b></span>
        <input [(ngModel)]="form.url" name="url" type="text" required/>
      </label>
    </div>
    <hr />
    <span class="field-label">Location</span>
    <p class="card note">Enter the address for the field or area you most commonly play at. If you don't have a regular field, or we're having trouble validating the address, try going "up a level" to the city, county, county, region, or even Country.</p>
    <div class="field-row">
      <label [ngClass]="{
            dirty: form.country,
            invalid: errors.country
      }">
        <span>Country<b>*</b></span>
        <input [(ngModel)]="form.country" name="country" type="text" required/>
      </label>
    </div>
    <div class="field-row">
      <label [ngClass]="{
            dirty: form.location,
            invalid: errors.location
      }">
        <span>Address / Location<b>*</b><em class="error" *ngIf="errors && errors.location">{{errors.location}}</em></span>
        <input [(ngModel)]="form.location" name="location" type="text" required/>
      </label>
    </div>
    <hr/>
    <span class="button submit" [ngClass]="{disabled:formCreateGroup.invalid, busy: busy}" (click)="submit()"><span class="text">Create Club</span><span class="spinner"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span></span>
  </form>
</div>
