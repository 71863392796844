import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { GeoPosition } from 'geo-position.ts';
import {environment} from "../../environments/environment";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public location: any = null;
  public allowGPS = false;

  private locationMonitor = new BehaviorSubject(null);
  currentLocation = this.locationMonitor.asObservable();

  constructor(private http: HttpClient) {
  }

  calcDistance(from: {lat: number, lon: number}, to: {lat: number, lon: number}){
    let fromGeo = new GeoPosition(from.lat, from.lon);
    let toGeo = new GeoPosition(to.lat, to.lon);

    let distance: any = fromGeo.Distance(toGeo).toFixed(0);


    distance = Math.round(distance / 1609.344);

    return distance as string;
  }

  geocode(address: string) {
    return this.http.get(environment.endpoint + '/locations/geocode?address=' + address);
  }

  locate(skipGPS = false){
    if (navigator.permissions) {
      navigator.permissions.query({name: "geolocation"}).then((res) => {
        if (!skipGPS && res && res.state === 'granted') {
          this.getPosition();
        } else {
          if (!this.location) {
            this.http.get(environment.endpoint + '/locations/locate').subscribe((res: any) => {
              if (res.location){
                this.setLocation([res.location.lat, res.location.lon], {
                  raw: res.location,
                  label: res.location.city || res.location.state,
                  source: 'ip'
                });
              }
            });
          }
        }
      });
    } else {
      this.http.get(environment.endpoint + '/locations/locate').subscribe((res: any) => {
        if (res.location){
          this.setLocation([res.location.lat, res.location.lon], {
            raw: res.location,
            label: res.location.city || res.location.state,
            source: 'ip'
          });
        }
      });
    }
  }

  getPosition(retries = 0){
    navigator.geolocation.getCurrentPosition((position) =>{
      if (position.coords) {
        this.allowGPS = true;
        this.setLocation([position.coords.latitude, position.coords.longitude], {
          raw: position,
          label: 'Device GPS',
          source: 'gps'
        })
      }
    },(e) =>{
      if (retries < 3) {
        this.getPosition(retries + 1);
      } else {
        this.locate(true);
      }
    },{timeout:5000})
  }

  setLocation(coordinates: [number, number], meta?: any){
    if (meta.source) {
      if (meta.source == 'ip') {
        if (this.location?.meta?.source == 'user' || this.location?.meta?.source == 'gps') {
          return
        }
      }

      if (meta.source == 'user') {
        if (this.location?.meta?.source == 'gps') {
          return
        }
      }
    }

    this.location = {
      lat: coordinates[0],
      lon: coordinates[1]
    };

    if (meta) {
      this.location.meta = meta;
    }

    this.locationMonitor.next(this.location);
  }

  resetLocation(){
    this.location = null;
    this.locate(true);
  }

  getRegions() {
    return this.http.get(environment.endpoint + '/locations/regions');
  }
}
