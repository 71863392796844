import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-club',
  templateUrl: './card-club.component.html',
  styleUrls: ['./card-club.component.less']
})
export class CardClubComponent implements OnInit {
  @Input() club: any;

  public focusMap: any = {
    'General' : 'fa-crosshairs',
    'Modding' : 'fa-screwdriver-wrench',
    'LARP' : 'fa-dice-d20',
    'Competitive' : 'fa-fire',
    'HvZ' : 'fa-biohazard',
    'Social' : 'fa-comment',
    'Venue' : 'fa-city',
  };

  constructor() { }

  ngOnInit(): void {
  }

}
