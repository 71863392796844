<div id="view-new-league" class="post-wrapper">
  <header>
      <h1>Post New Build</h1>
      <div class="card warning centered" *ngIf="errors.limit">You have reached your build limit, please remove some of your older builds to make room for new ones!</div>
      <div class="card error centered" *ngIf="errors.server">Hmm. Something went wrong trying to create this build. Usually this means your phones were too large, try using smaller photos, or only starting with one photo (and add the rest later.)</div>
      <div class="card error centered" *ngIf="errored && !errors.limit && !errors.server">Oops! It looks like there are some fields highlighted in red below that need your attention.</div>
      <div class="masthead">
        Do you have a fancy new blaster in your loadout, new mod, or a unique integration that you're eager to show off? Simply snap a few photos, fill in some details below, and share it with the community!
      </div>
  </header>
  <form #formCreateGroup="ngForm" class="padding-bottom-20">
    <span class="field-label">Basic Info</span>
    <div class="field-row">
      <label>
        <span>Blaster Name<em>Optional</em></span>
        <input [(ngModel)]="form.name" name="name" type="text"/>
      </label>
      <label>
        <span>Blaster Type<b>*</b></span>
        <select [(ngModel)]="form.blaster" name="type" (ngModelChange)="onBlasterChange($event)">
          <option>Other</option>
          <option *ngFor="let blaster of blasters" value="{{blaster._id}}">{{blaster.manufacturer}} - {{blaster.name}}</option>
        </select>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>Power Source</span>
        <select [(ngModel)]="form.category" name="category">
          <option>Other</option>
          <option>Spring Powered</option>
          <option>Flywheel Powered</option>
          <option>String Powered</option>
          <option>HPA/LPA Powered</option>
        </select>
      </label>
      <div class="label">
        <div class="field-row">
          <label>
            <span>Average FPS</span>
            <input [(ngModel)]="form.fps" name="fps" type="number"/>
          </label>
          <label>
            <span>Rate of Fire</span>
            <select [(ngModel)]="form.rof" name="rof">
              <option>Single Shot</option>
              <option>Semi-Auto</option>
              <option>Burst Fire</option>
              <option>Full-Auto</option>
            </select>
          </label>
        </div>
      </div>
    </div>
    <div class="field-row">
      <label>
        <span>Tell Us About It</span>
        <textarea [(ngModel)]="form.description" name="description"></textarea>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>External URL</span>
        <input [(ngModel)]="form.url" name="url" type="text"/>
      </label>
    </div>
    <span class="field-label">Photos</span>
    <div class="field-row">
      <label class="upload" [ngClass]="{
                dirty: true,
                invalid: errors.photoOne
          }">
        <span>Photo #1<b>*</b> <em>800x800</em></span>
        <div class="input">
          <div class="preview">
            <span *ngIf="!form.photoOne"><i class="fa-solid fa-ban"></i></span>
            <img *ngIf="form.photoOne" [src]="form.photoOne"/>
          </div>
          <span *ngIf="form.photoOne" class="action"><i
            class="fa-solid fa-arrows-rotate"></i> Replace File</span>
          <span *ngIf="!form.photoOne" class="action"><i class="fa-solid fa-arrow-up"></i> Select File</span>
          <input type="file" (change)="onFileSelected('photoOne', $event)" required />
        </div>
      </label>
      <label class="upload">
        <span>Photo #2 <em>800x800</em></span>
        <div class="input">
          <div class="preview">
            <span *ngIf="!form.photoTwo"><i class="fa-solid fa-ban"></i></span>
            <img *ngIf="form.photoTwo" [src]="form.photoTwo"/>
          </div>
          <span *ngIf="form.photoTwo" class="action"><i
            class="fa-solid fa-arrows-rotate"></i> Replace File</span>
          <span *ngIf="!form.photoTwo" class="action"><i class="fa-solid fa-arrow-up"></i> Select File</span>
          <input type="file" (change)="onFileSelected('photoTwo', $event)"/>
        </div>
      </label>
    </div>
    <div class="field-row">
      <label class="upload">
        <span>Photo #3 <em>800x800</em></span>
        <div class="input">
          <div class="preview">
            <span *ngIf="!form.photoThree"><i class="fa-solid fa-ban"></i></span>
            <img *ngIf="form.photoThree" [src]="form.photoThree"/>
          </div>
          <span *ngIf="form.photoThree" class="action"><i
            class="fa-solid fa-arrows-rotate"></i> Replace File</span>
          <span *ngIf="!form.photoThree" class="action"><i class="fa-solid fa-arrow-up"></i> Select File</span>
          <input type="file" (change)="onFileSelected('photoThree', $event)"/>
        </div>
      </label>
      <label class="upload">
        <span>Photo #4 <em>800x800</em></span>
        <div class="input">
          <div class="preview">
            <span *ngIf="!form.photoFour"><i class="fa-solid fa-ban"></i></span>
            <img *ngIf="form.photoFour" [src]="form.photoFour"/>
          </div>
          <span *ngIf="form.photoFour" class="action"><i
            class="fa-solid fa-arrows-rotate"></i> Replace File</span>
          <span *ngIf="!form.photoFour" class="action"><i class="fa-solid fa-arrow-up"></i> Select File</span>
          <input type="file" (change)="onFileSelected('photoFour', $event)"/>
        </div>
      </label>
    </div>
    <ng-container *ngIf="advanced">
      <span class="field-label">Barrel</span>
      <div class="field-row">
        <label>
          <span>Diameter</span>
          <input [(ngModel)]="form.barrel.diameter" name="barrel-diameter" type="text"/>
        </label>
        <label>
          <span>Length</span>
          <input [(ngModel)]="form.barrel.length" name="barrel-length" type="text"/>
        </label>
      </div>
      <ng-container *ngIf="form.category == 'Other' ||form.category == 'Spring Powered' || form.category == 'String Powered'">
        <span class="field-label">Plunger</span>
        <div class="field-row">
          <label>
            <span>Volume</span>
            <input [(ngModel)]="form.plunger.volume" name="plunger-volume" type="text"/>
          </label>
          <label>
            <span>Spring</span>
            <input [(ngModel)]="form.plunger.spring" name="plunger-spring" type="text"/>
          </label>
        </div>
      </ng-container>
      <ng-container *ngIf="form.category == 'Other' || form.category == 'Flywheel Powered'">
        <span class="field-label">Battery</span>
        <div class="field-row">
          <label>
            <span>Cell Count</span>
            <input [(ngModel)]="form.battery.cells" name="battery-cells" type="number"/>
          </label>
          <label>
            <span>Voltage<em></em></span>
            <input [(ngModel)]="form.battery.voltage" name="battery-voltage" type="number"/>
          </label>
          <label>
            <span>C-Rating<em></em></span>
            <input [(ngModel)]="form.battery.rating" name="battery-rating" type="number"/>
          </label>
          <label>
            <span>Capacity<em>mAh</em></span>
            <input [(ngModel)]="form.battery.capacity" name="battery-capacity" type="number"/>
          </label>
        </div>
        <span class="field-label">Flywheels</span>
        <div class="field-row">
          <label>
            <span>Cage</span>
            <input [(ngModel)]="form.stages[0].cage" name="stage-0-cage" type="text"/>
          </label>
          <label>
            <span>Crush<em>Millimeters</em></span>
            <input [(ngModel)]="form.stages[0].crush" name="stage-0-cage" type="number" min="20" max="44"/>
          </label>
        </div>
        <div class="field-row">
          <label>
            <span>Motors</span>
            <input [(ngModel)]="form.stages[0].motors" name="stage-0-motors" type="text"/>
          </label>
          <label>
            <span>Flywheels</span>
            <input [(ngModel)]="form.stages[0].wheels" name="stage-0-wheels" type="text"/>
          </label>
        </div>
        <ng-container *ngIf="form.stages[0].cage || form.stages[0].motors || form.stages[0].wheels">
          <span class="field-label">Flywheels (Stage 2)</span>
          <div class="field-row">
            <label>
              <span>Cage</span>
              <input [(ngModel)]="form.stages[1].cage" name="stage-0-cage" type="text"/>
            </label>
            <label>
              <span>Crush<em>Millimeters</em></span>
              <input [(ngModel)]="form.stages[1].crush" name="stage-0-cage" type="number" min="20" max="44"/>
            </label>
          </div>
          <div class="field-row">
            <label>
              <span>Motors</span>
              <input [(ngModel)]="form.stages[1].motors" name="stage-0-motors" type="text"/>
            </label>
            <label>
              <span>Flywheels</span>
              <input [(ngModel)]="form.stages[1].wheels" name="stage-0-wheels" type="text"/>
            </label>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <hr/>
    <span class="button outline showmore" (click)="advanced = !advanced;">{{advanced ? 'Hide' : 'Show'}} Internals</span>
    <span class="button submit" [ngClass]="{disabled:formCreateGroup.invalid, busy: busy}" (click)="submit()"><span class="text">Add Build</span><span class="spinner"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span></span>
  </form>
</div>
