import {Component, OnInit} from '@angular/core';
import {BuildService} from "../../services/build.service";
import {Meta, Title} from "@angular/platform-browser";
import {PostService} from "../../services/post.service";
import {ProductService} from "../../services/products.service";
import {LocationService} from "../../services/location.service";
import {ClubService} from "../../services/club.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.less']
})
export class ExploreComponent implements OnInit {
  public trending: any = {
    builds: [],
    posts: []
  };

  public latest: any = {
    builds: [],
    posts: [],
    products: []
  };

  public nearby: any = {
    clubs: [],
    geoJSON: []
  };

  public loading: any = {
    posts: true,
    builds: true,
    products: true,
  }

  // TODO: Nearby events/clubs
  public rotation: any = [
    'builds_trending',
    'builds_latest',
    'product_trending',
    'products_latest',
    'posts_trending',
  ];

  constructor(private meta: Meta, private title: Title, public authService:AuthService, public locationService: LocationService, public clubService: ClubService, public buildService: BuildService, public postService: PostService, public productService: ProductService) {
  }

  ngOnInit(): void {
    this.fetchBuilds(true);
    this.fetchTrendingBuilds(true);
    this.fetchPosts(true);
    this.fetchProducts(true);

    this.locationService.locate(true);

    this.locationService.currentLocation.subscribe((data: any) => {
      if (data && data.lat && data.lon) {
        this.fetchGeoJSON();
        this.fetchClubs(true);
      }
    });
  }

  fetchGeoJSON() {
    this.clubService.getGeoJSON().subscribe((res: any) => {
      this.nearby.geoJSON = res;
    });
  }

  fetchClubs(reset = false) {
    let skip = reset ? 0 : this.nearby.clubs.length;
    this.clubService.getClubs('nearest', {
      active: true,
      near: this.locationService.location
    }, 3, skip).subscribe((res: any) => {
      if (reset) {
        this.nearby.clubs = [];
      }

      res.items.forEach((club: any) => {
        if (this.locationService.location) {
          club.distance = this.locationService.calcDistance(this.locationService.location, {
            lat: club.location.coordinates[1],
            lon: club.location.coordinates[0]
          })
        }

        this.nearby.clubs.push(club);
      });
    });
  }

  fetchBuilds(reset = false) {
    this.buildService.getBuilds('newest', null, 16).subscribe((res: any) => {
      if (reset) {
        this.latest.builds = [];
      }

      this.latest.builds = this.latest.builds.concat(res.items);
    });
  }

  fetchTrendingBuilds(reset = false) {
    this.buildService.getBuilds('hottest', null, 4).subscribe((res: any) => {
      if (reset) {
        this.trending.builds = [];
      }

      this.trending.builds = this.trending.builds.concat(res.items);
    });
  }

  fetchPosts(reset = false) {
    let skip = reset ? 0 : this.latest.posts.length;
    this.postService.getPosts(['all'], null, 16, skip).subscribe((res: any) => {
      if (reset) {
        this.latest.posts = [];
      }

      this.latest.posts = this.latest.posts.concat(res.items);
      this.loading.posts = false;
    });
  }

  fetchProducts(reset = false) {
    let skip = reset ? 0 : this.latest.products.length;
    this.productService.getProducts('newest', {}, 16, skip).subscribe((res: any) => {
      if (reset) {
        this.latest.products = res.items;
      } else {
        this.latest.products = this.latest.products.concat(res.items);
      }
    });
  }

}
