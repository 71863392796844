import { Component, OnInit } from '@angular/core';
import {LocationService} from "../../services/location.service";
import {GameService} from "../../services/game.service";
import {AuthService} from "../../services/auth.service";
import {OrganizationService} from "../../services/organizationService";
import {ToastService} from "../../services/toast.service";
import {Router} from "@angular/router";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-new-game',
  templateUrl: './new-game.component.html',
  styleUrls: ['./new-game.component.less']
})
export class NewGameComponent implements OnInit {
  public form = {
    title: '',
    url: '',
    description: '',
    rules: '',
    location: '',
    start: null,
    end: null,
    host: 'me',
    club: null,
    format: 'Event',
    public: 'true',
    cap: 100,
    masks: false,
    vaccine: false
  };

  public errors: any = {};

  public busy = false;
  public errored = false;

  constructor(public router: Router, public locationService: LocationService, public dataService: DataService, public authService:AuthService, public toastService: ToastService, public gameService: GameService,  public organizationService: OrganizationService) { }

  ngOnInit(): void {
  }

  submit(): void {
    this.busy = true;
    this.errors = {};

    this.gameService.createGame(this.form).subscribe((res: any)=>{
      this.busy = false;
      if (res.status == 200) {
        if (res.game) {
          this.router.navigate(['/events/' + res.game._id]);
        }

        if (res.toast) {
          this.toastService.addToast(res.toast.type, res.toast.title, res.toast.text);
        }
      } else {
        this.errors = res.errors;
        this.errored = true;

        this.dataService.broadcast({name: 'scrollReset'});
      }
    }, (err)=>{
      this.errored = true;
      this.busy = false;

      this.dataService.broadcast({name: 'scrollReset'});
    });
  }

}
