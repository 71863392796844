import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) { }

  search(term: string, limit: number = 8, skip: number = 0) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    return this.http.get(environment.endpoint + '/search/' + term + filter);
  }
}
