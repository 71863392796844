import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-handle',
  templateUrl: './handle.component.html',
  styleUrls: ['./handle.component.less']
})
export class HandleComponent implements OnInit {
  @Input() player: any;
  @Input() club: any;
  constructor() { }

  ngOnInit(): void {
  }

}
