import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {LocationService} from "../../services/location.service";
import {TeamService} from "../../services/team.service";
import {FormatService} from "../../services/format.service";
import {LeagueService} from "../../services/league.service";
import {OrganizationService} from "../../services/organizationService";

@Component({
  selector: 'app-new-team',
  templateUrl: './new-team.component.html',
  styleUrls: ['./new-team.component.less']
})
export class NewTeamComponent implements OnInit {
  public organizations: any[] = [];
  public regions: any[] = [];
  public regionMap: any = {
    'Central Asia':'fa-earth-asia',
    'Southern Asia':'fa-earth-asia',
    'Southeast Asia':'fa-earth-asia',
    'East Asia':'fa-earth-asia',
    'Western Asia':'fa-earth-asia',
    'Central Africa':'fa-earth-africa',
    'North Africa':'fa-earth-africa',
    'Southern Africa':'fa-earth-africa',
    'East Africa':'fa-earth-africa',
    'West Africa':'fa-earth-africa',
    'Central America':'fa-earth-americas',
    'Northern America':'fa-earth-americas',
    'South America':'fa-earth-americas',
    'Caribbean':'fa-earth-americas',
    'Antartica':'fa-earth-americas',
    'Northern Europe':'fa-earth-europe',
    'Southern Europe':'fa-earth-europe',
    'Eastern Europe':'fa-earth-europe',
    'Western Europe':'fa-earth-europe',
    'Australia':'fa-earth-oceania',
    'Melanesia':'fa-earth-oceania',
    'Micronesia':'fa-earth-oceania',
    'Polynesia':'fa-earth-oceania'
  };

  public form = {
    name: '',
    tag: '',
    organization: 'Freelance',
    url: '',
    description: '',
    location: '',
    country: '',
    primarycolor: '#4D5458',
    secondarycolor: '#4D5458',
    accentcolor: '#0089ff',
  };

  public errors: any = {};

  public busy = false;
  public errored = false;

  constructor(public router: Router, public locationService: LocationService, public teamService: TeamService, public organizationService: OrganizationService) { }


  ngOnInit(): void {
    this.locationService.getRegions().subscribe((res: any) => {
      this.regions = Object.values(res.regions);
    });

    this.organizationService.getOrganizations().subscribe((res: any) => {
      this.organizations = res.items;
    });
  }

  submit(): void {
    this.busy = true;
    this.teamService.createTeam(this.form).subscribe((res: any)=>{
      this.busy = false;
      // If res.status == 200, it's good, otherwise error
      if (res.status == 200) {
        if (res.team) {
          this.router.navigate(['/teams/' + res.team._id])
        }
      } else {
        let masthead  = document.getElementById('primary-nav');
        if (masthead) {
          masthead.scrollIntoView();
        }
      }
    }, (err: any)=>{
      this.busy = false;
      let masthead  = document.getElementById('primary-nav');
      if (masthead) {
        masthead.scrollIntoView();
      }
    });
  }

}
