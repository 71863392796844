<header>
  <div class="wrapper">
    <h1>Page Not Found</h1>
    <div class="masthead animate__animated animate__fadeIn">
      <p>Oops! It looks like this page is missing.</p>
    </div>
  </div>
</header>
<div class="wrapper content">

</div>
