import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-post',
  templateUrl: './card-post.component.html',
  styleUrls: ['./card-post.component.less']
})
export class CardPostComponent implements OnInit {
  @Input() post: any;
  @Input() view: string = 'grid';

  public typeMap: any = {
    'article' : 'fa-newspaper',
    'discussion' : 'fa-align-left',
    'question' : 'fa-hand'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
