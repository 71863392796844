import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NewGameComponent} from "./views/new-game/new-game.component";
import {TermsComponent} from "./views/terms/terms.component";
import {NewClubComponent} from "./views/new-club/new-club.component";
import {NewTeamComponent} from "./views/new-team/new-team.component";
import {NotfoundComponent} from "./views/notfound/notfound.component";
import {NewBuildComponent} from "./views/new-build/new-build.component";
import {NewBlasterComponent} from "./views/new-blaster/new-blaster.component";
import {OnboardingComponent} from "./views/onboarding/onboarding.component";
import {ExploreComponent} from "./views/explore/explore.component";

const routes: Routes = [
  {path: 'play', redirectTo: 'events'},
  { path: 'games',   redirectTo: 'events' },
  {path: 'blasters', loadChildren: () => import('./modules/blasters.module').then(m => m.BlastersModule) },
  {path: 'builds', loadChildren: () => import('./modules/builds.module').then(m => m.BuildsModule) },
  {path: 'clubs', loadChildren: () => import('./modules/clubs.module').then(m => m.ClubsModule) },
  {path: 'players', loadChildren: () => import('./modules/players.module').then(m => m.PlayersModule) },
  {path: 'posts', loadChildren: () => import('./modules/posts.module').then(m => m.PostsModule) },
  {path: 'events', loadChildren: () => import('./modules/games.module').then(m => m.GamesModule) },
  {path: 'market', loadChildren: () => import('./modules/products.module').then(m => m.ProductsModule) },
  {path: 'teams', loadChildren: () => import('./modules/teams.module').then(m => m.TeamsModule) },
  {path: 'new/club', component: NewClubComponent},
  {path: 'new/event', component: NewGameComponent},
  {path: 'new/team', component: NewTeamComponent},
  {path: 'new/blaster', component: NewBlasterComponent},
  {path: 'new/build', component: NewBuildComponent},
  {path: 'getstarted', component: OnboardingComponent},
  {path: 'legal', component: TermsComponent},
  {path: '', component: ExploreComponent},
  {path: '**', component: NotfoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    paramsInheritanceStrategy: 'always',
    // preloadingStrategy:PreloadAllModules,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
