import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-build',
  templateUrl: './card-build.component.html',
  styleUrls: ['./card-build.component.less']
})
export class CardBuildComponent implements OnInit {
  @Input() build: any;
  @Input() view: string = 'grid';

  constructor() { }

  ngOnInit(): void {
  }

}
