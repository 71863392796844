<div id="app-container" class="conversations-{{showconversations ? 'open' : 'closed'}}">
  <div id="sidebar-wrapper" class="nomobile" [ngClass]="{active: showMenu}">
    <nav id="primary-nav">
      <div class="logo nomobile">
        <h4 routerLink="/">
          <img src="assets/img/logo-retina.png" alt="DartSweep Logo" height="60" width="78" />
        </h4>
<!--        <i (click)="test()" class="icon fa-solid fa-code"></i>-->
      </div>
<!--      <div class="search-bar mobileonly-flex">-->
<!--        <i class="fa-solid {{searchBusy ? 'fa-spin fa-spinner' : 'fa-magnifying-glass'}}"></i>-->
<!--        <input [(ngModel)]="searchText" (ngModelChange)="search.next($event)" #searchBar type="text"/>-->
<!--        <span class="noresults" [ngClass]="{active: searchText && !searchBusy && !searchResults.length}">No Results Found</span>-->
<!--        <div class="search-results" [ngClass]="{active: showsearch && searchResults.length}">-->
<!--          <ul class="result-list">-->
<!--            <li class="result" *ngFor="let result of searchResults" routerLink="{{result.url}}">-->
<!--              <span class="user-photo" [ngStyle]="{'background-image':(result.thumbnail ? 'url(' + result.thumbnail + ')' : null)}">-->
<!--                <img class="thumbnail lazyload" *ngIf="result.photo" src="{{result.photo}}"/>-->
<!--              </span>-->
<!--              <div class="details">-->
<!--                <span class="title">{{result.name}}</span>-->
<!--                <span class="type"><i class="fa-solid fa-hashtag"></i>{{result.type}}</span>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
      <ul class="nav-list">
        <li class="nav-label"><span>Navigation</span></li>
<!--        <li><a class="highlighted" routerLink="/getstarted" routerLinkActive="active"><i class="icon fa-solid fa-rocket fa-beat"></i> <span>Start Here</span><span class="icon anchored progress">2 of 3</span></a></li>-->
        <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="icon fa-solid fa-compass"></i> <span>Explore</span></a></li>
        <li><a routerLink="/posts" routerLinkActive="active"><i class="icon fa-solid fa-message"></i> <span>Posts</span></a></li>
        <li><a routerLink="/builds" routerLinkActive="active"><i class="icon fa-solid fa-camera"></i> <span>Builds</span></a></li>
        <li><a routerLink="/events" routerLinkActive="active"><i class="icon fa-solid fa-calendar-days"></i> <span>Events</span></a></li>
        <li><a routerLink="/clubs" routerLinkActive="active"><i class="icon fa-solid fa-earth-americas"></i><span>Clubs</span></a></li>
        <li><a routerLink="/teams" routerLinkActive="active"><i class="icon fa-solid fa-trophy"></i><span>Teams</span></a></li>
        <li><span class="clickable" (click)="toggleNavItem(['blasters'])"><i class="icon fa-solid fa-chalkboard"></i><span>Learn</span><i class="icon anchored fa-solid fa-chevron-{{nav.blasters || nav.builds ? 'down' : 'right'}}"></i></span></li>
        <li class="inset" *ngIf="nav.blasters"><a routerLink="/blasters" routerLinkActive="active"><i class="icon fa-solid fa-box"></i><span>Blasters</span></a></li>
        <li class="inset disabled" *ngIf="nav.blasters"><a><i class="icon fa-solid fa-book"></i><span>Library</span><i class="icon anchored fa-solid fa-lock"></i></a></li>
        <li class="disabled"><a routerLink="/printing" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="icon fa-solid fa-compass-drafting"></i><span>Designs</span><i class="icon anchored fa-solid fa-lock"></i></a></li>
        <li><a routerLink="/market" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="icon fa-solid fa-cart-shopping"></i><span>Market</span></a></li>
      </ul>
      <ul class="nav-list">
        <li class="nav-label"><span>Location</span></li>
        <li><span class="clickable" (click)="openTool('geolocation')"><i class="icon fa-solid {{locationSourceMap[this.locationService.location?.meta?.source] || 'fa-circle-notch fa-spin'}}"></i><span>{{locationService.location?.meta?.label || ''}}</span></span></li>
      </ul>
      <ul class="nav-list">
        <li class="nav-label"><span>Account</span></li>
        <li *ngIf="authService.user"><a [routerLink]="['/players', authService.user._id]" routerLinkActive="active"><i class="icon fa-solid fa-user"></i><span>My Profile</span></a></li>
        <li *ngIf="authService.user"><span class="clickable" (click)="openTool('redeem')"><i class="icon fa-solid fa-gift"></i><span>Redeem</span></span></li>
        <li *ngIf="!authService.user" (click)="showSignIn();"><span class="clickable"><i class="icon fa-solid fa-arrow-right-to-bracket"></i><span>Sign in / Sign Up</span></span></li>
        <li *ngIf="authService.user" (click)="authService.signOut();"><span class="clickable"><i class="icon fa-solid fa-arrow-right-from-bracket"></i><span>Sign Out</span></span></li>
      </ul>
      <ul class="nav-list" *ngIf="authService && authService.user">
        <li class="nav-label"><span>Quick Links</span></li>
        <li *ngFor="let club of authService.user.clubs">
          <a routerLink="/clubs/{{club._id}}" routerLinkActive="active">
            <span class="icon thumbnail">
              <img *ngIf="club.logo" src="{{club.logo}}" height="24" width="24" alt="{{club.tag}} Logo" />
              <img *ngIf="!club.logo && club.banner" src="{{club.banner}}" height="24" width="24" alt="{{club.tag}} Banner" />
              <span *ngIf="!club.banner && !club.logo" class="initials">{{club.tag | initials}}</span>
            </span>
            <span>{{club.tag}}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div id="primary-wrapper">
    <nav id="utility-nav" aria-labelledby="primary">
      <ul class="nav-list mobileonly">
        <li class="square" (click)="toggleMenu()">
          <i class="fa-solid {{showMenu ? 'fa-xmark' : 'fa-bars'}}"></i>
        </li>
      </ul>
      <div class="logo mobileonly">
        <img routerLink="/" src="assets/img/logo-retina.png">
      </div>
      <div class="search-bar nomobile">
        <i class="fa-solid {{searchBusy ? 'fa-spin fa-spinner' : 'fa-magnifying-glass'}}"></i>
        <input [(ngModel)]="searchText" (ngModelChange)="search.next($event)" #searchBar type="text"/>
        <span class="noresults" [ngClass]="{active: searchText && !searchBusy && !searchResults.length}">No Results Found</span>
        <div class="search-results" [ngClass]="{active: showsearch && searchResults.length}">
          <ul class="result-list">
            <li class="result" *ngFor="let result of searchResults" routerLink="{{result.url}}">
              <span class="user-photo" [ngStyle]="{'background-image':(result.thumbnail ? 'url(' + result.thumbnail + ')' : null)}">
                <img class="thumbnail lazyload" *ngIf="result.photo" src="{{result.photo}}"/>
              </span>
              <div class="details">
                <span class="title">{{result.name}}</span>
                <span class="type"><i class="fa-solid fa-hashtag"></i>{{result.type}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <ul class="nav-list">
        <li class="square mobileonly" *ngIf="!authService.user" (click)="showSignIn()">
          <i class="fa-solid fa-circle-user"></i>
        </li>
          <li class="square nomobile" (click)="authService.user ? showToolMenu() : showSignIn()">
            <i class="fa-solid fa-plus"></i>
          </li>
          <li class="square notification-button cropped" tabindex="0" *ngIf="authService.user">
            <i class="fa-solid fa-bell nomobile"></i>
            <span class="user-photo mobileonly-flex">
              <img *ngIf="authService.user.photo" src="{{authService.user.photo}}"/>
              <span class="initials" *ngIf="!authService.user.photo">{{authService.user.name | initials}}</span>
            </span>
            <b class="notification-bubble" *ngIf="notifications && unread">{{unread}}</b>
            <ul class="context-menu">
              <div *ngIf="authService.user" class="mobileonly">
                <li class="label">Your Profile</li>
                <li class="notification" routerLink="/players/{{authService.user._id}}">
                  <span class="user-photo">
                    <img *ngIf="authService.user.photo" src="{{authService.user.photo}}"/>
                    <span class="initials" *ngIf="!authService.user.photo">{{authService.user.name | initials}}</span>
                  </span>
                  <div class="details">
                    <span class="text"><a href="/players/{{authService.user._id}}"><i class="icon-small fa-solid fa-at"></i>{{authService.user.handle}}</a></span>
                    <span class="time">{{authService.user.name}}</span>
                  </div>
                </li>
              </div>
              <ng-container *ngIf="conversations.length">
              <li class="label">Conversations</li>
              <li class="notification" *ngFor="let conversation of conversations" routerLink="/{{conversation.type}}s/{{conversation.key.split('-')[1]}}">
                <div class="user-photo">
                  <i class="icon fa-solid fa-hashtag"></i>
                </div>
                <div class="details">
                  <span class="text"><a href="/{{conversation.type}}s/{{conversation.key.split('-')[1]}}">{{conversation.build?.name || conversation.post?.title || 'Conversation'}}</a></span>
                  <span class="time">{{conversation.unread}} Unread Messages</span>
                </div>
              </li>
              </ng-container>
              <li class="label">Notifications</li>
              <li class="notification" [ngClass]="{read: notification.read}" *ngFor="let notification of notifications" (hesitate)="dismissNotification(notification)" [duration]="1500" (click)="dismissNotification(notification)">
                <div class="user-photo">
                  <img *ngIf="notification.photo" src="{{notification.photo}}" />
                  <i *ngIf="!notification.photo" class="icon fa-solid fa-square-arrow-up-right"></i>
                </div>
                <div class="details">
                  <span class="text"><markdown [inline]="true" class="inline" [data]="notification.message || notification.text | mentions"></markdown></span>
                  <span class="time">{{notification.createdAt | timeAgo}}</span>
                </div>
              </li>
            </ul>
          </li>
          <ng-container *ngIf="authService.user">
            <li class="square nomobile cropped" [routerLink]="['/players', authService.user._id]">
            <span class="user-photo">
              <img *ngIf="authService.user.photo" src="{{authService.user.photo}}" height="56" width="56" alt="{{authService.user.handle}}'s Photo" />
              <span class="initials" *ngIf="!authService.user.photo">{{authService.user.name | initials}}</span>
            </span>
            </li>
          </ng-container>
        </ul>
      <ul class="toast-list" *ngIf="toastService.queue.length">
        <li *ngFor="let toast of toastService.queue.slice(0, 1)" class="animate__animated animate__slideInRight animate__faster">
          <div class="user-photo">
            <img *ngIf="toast.photo" src="{{toast.photo}}" />
            <i *ngIf="!toast.photo" class="fa-solid {{toastMap[toast.type] || 'fa-bell'}}"></i>
          </div>
          <div class="toast-body">
            <span class="toast-header">
              <span class="title">{{toast.title}}</span>
            </span>
              <span class="text">{{toast.text}}</span>
          </div>
        </li>
      </ul>
    </nav>
    <div #scrollWrapper class="outer-wrapper setting-chatbelow-{{this.authService.user?.settings?.chatbelow ? 'true' : 'false'}}">
      <router-outlet></router-outlet>
      <footer id="footer">
      <div class="post-wrapper wide">
        <div class="cta-card discord">
          <span class="title">Join the Conversation</span>
          <a class="button" href="https://discord.gg/pQWY3Z28tZ" target="_blank"><i class="icon fa-brands fa-discord"></i> discord.gg/pQWY3Z28tZ</a>
          <span class="note">Chat about foam flinging, share suggestions, keep tabs on updates.</span>
        </div>
        <div class="links">
          <ul>
              <li><a href="/legal">Terms & Privacy</a></li>
              <li (click)="openTool('contact')"><span class="clickable">Contact</span></li>
              <li><a href="https://github.com/users/BrandonDiaz/projects/1/views/1" target="_blank">Roadmap</a> <i class="fa-solid fa-arrow-up-right-from-square"></i></li>
          </ul>
          <ul>
              <li class="disabled">Developers</li>
              <li><a href="https://status.dartsweep.io/" target="_blank">Status</a> <i class="fa-solid fa-arrow-up-right-from-square"></i></li>
          </ul>
        </div>
        <div class="disclaimer">DartSweep is not affiliated, associated, endorsed by, or in any way connected with Nerf, Dart Zone or any
          other brands referenced here. We just like flinging foam, be cool.</div>
        <div class="legal">
          <div class="credit">
            <span><i class="fa-regular fa-copyright"></i> Brandon Diaz LLC. All rights reserved. Build {{buildId}}</span>
          </div>
          <div class="credit">
            <span>Built with <i class="fa-solid fa-heart"></i> in Oregon</span>
          </div>
        </div>

      </div>
      </footer>
    </div>
    <div id="view-auth-signin" class="modal-wrapper" (click)="cancelSignIn($event); false" [ngClass]="{
active: forms.signin.visible
}">
      <div class="modal">
      <span class="header with-note">
        <span class="title">
          Sign In to DartSweep
        </span>
      </span>
        <p class="note">
          Just enter your email address below, and we'll send you a single-use use code in order to sign in — easy peasy.
        </p>
        <p class="note">
          If you're new here, we'll just ask you for a few details and then create an account for you automatically.
        </p>
        <div class="form">
          <div class="step step-1" [ngClass]="{active: forms.signin.step == 1}">
            <div class="form">
              <label class="wide" [ngClass]="{
              dirty: forms.signin.errors.email,
              invalid: forms.signin.errors.email
            }">
                <span>Email Address</span>
                <input type="email" [(ngModel)]="forms.signin.data.email"/>
              </label>
              <ng-container *ngIf="forms.signin.signup">
                <label class="wide" [ngClass]="{
                dirty: forms.signin.errors.handle,
                invalid: forms.signin.errors.handle
              }">
                  <span>Handle / Username</span>
                  <input type="email" [(ngModel)]="forms.signin.data.handle"/>
                </label>
                <label class="wide" [ngClass]="{
                dirty: forms.signin.errors.name,
                invalid: forms.signin.errors.name
              }">
                  <span>Display Name</span>
                  <input type="email" [(ngModel)]="forms.signin.data.name"/>
                </label>
              </ng-container>
            </div>
            <footer>
              <div class="actions buttons">
              <span class="button close" (click)="cancelSignIn();">
                {{forms.signin.signup ? 'Cancel' : 'Close'}}
              </span>
                <span class="next button" [ngClass]="{
                busy:forms.signin.busy,
                disabled: (forms.signin.signup && (!forms.signin.data.name || !forms.signin.data.email || !forms.signin.data.handle)) || (!forms.signin.signup && (!forms.signin.data.email))
              }" (click)="signIn();">
<!--                <i class="fa-solid {{forms.signin.busy ? 'fa-gear fa-spin' : 'fa-wand-magic-sparkles'}}"></i>-->
                  {{forms.signin.signup ? 'Sign Me Up' : 'Request Code'}}
              </span>
              </div>
            </footer>
          </div>
          <div class="step step-2" [ngClass]="{active: forms.signin.step == 2}">
            <div class="form">
              <label class="wide">
                <span>Magic Code</span>
                <div class="two-factor">
                  <input #authToken0 type="number" inputmode="numeric" (paste)="twoFactorPaste($event)" (keyup)="twoFactorEntry($event)"
                         [(ngModel)]="forms.signin.data.token[0]"/>
                  <input #authToken1 type="number" inputmode="numeric" (keyup)="twoFactorEntry($event)"
                         [(ngModel)]="forms.signin.data.token[1]"/>
                  <input #authToken2 type="number" inputmode="numeric" (keyup)="twoFactorEntry($event)"
                         [(ngModel)]="forms.signin.data.token[2]"/>
                  <input #authToken3 type="number" inputmode="numeric" (keyup)="twoFactorEntry($event)"
                         [(ngModel)]="forms.signin.data.token[3]"/>
                  <input #authToken4 type="number" inputmode="numeric" (keyup)="twoFactorEntry($event)"
                         [(ngModel)]="forms.signin.data.token[4]"/>
                  <input #authToken5 type="number" inputmode="numeric" (keyup)="verifyToken()"
                         [(ngModel)]="forms.signin.data.token[5]"/>
                </div>
              </label>
            </div>
            <footer>
              <div class="actions buttons">
              <span class="button close" (click)="this.forms.signin.step= 1;">
                Cancel
              </span>
                <span class="submit button" [ngClass]="{busy:forms.signin.busy}" (click)="verifyToken();">
                Verify Code
              </span>
              </div>
              <span class="meta">No dice? <span class="clickable" (click)="signIn();">Try sending a new code</span></span>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="quick-actions" class="mobileonly-flex">
  <span *ngIf="showScrollReset" class="button round animate__animated animate__fadeIn" (click)="triggerScrollReset()"><i class="fa-solid fa-arrow-up-long"></i></span>
  <span *ngIf="authService.user" (click)="showToolMenu()" class="button round"><i class="fa-solid fa-plus"></i></span>
</div>
<div class="modal-wrapper" [ngClass]="{active: showTools && tool == ''}" *ngIf="authService.user">

  <div class="modal tool-menu">
    <div class="header"><span class="title">What Are You Posting?</span><span class="action" (click)="hideToolMenu()"><i class="icon square fa-regular fa-circle-xmark"></i></span></div>
    <ul>
      <li (click)="openTool('share', {type:'discussion'})"><i class="icon fa-solid fa-message"></i><span>Discussion</span></li>
      <li (click)="openTool('share', {type:'question'})"><i class="fa-solid fa-hand"></i><span>Question</span></li>
      <li (click)="openTool('share', {type:'news'})"><i class="fa-solid fa-newspaper"></i><span>News</span></li>
      <li (click)="openTool('share', {type:'media'})"><i class="fa-brands fa-youtube"></i><span>Media</span></li>
      <li routerLink="/new/build"><i class="fa-solid fa-camera"></i><span>Build</span></li>
      <li routerLink="/new/event"><i class="fa-solid fa-calendar-days"></i><span>Event</span></li>
      <li routerLink="/new/club"><i class="fa-solid fa-location-dot"></i><span>Club</span></li>
      <li routerLink="/new/team"><i class="fa-solid fa-trophy"></i><span>Team</span></li>
    </ul>
    <div class="buttons mobileonly">
      <span class="button close" (click)="hideToolMenu()">Close {{tool}}</span>
    </div>
  </div>
</div>
<div class="modal-wrapper" [ngClass]="{active: showTools && tool == 'share'}" *ngIf="authService.user">
  <div class="modal">
    <div class="header with-note">
      <span class="title">Share Your {{toolData?.type || 'Discussion'}}</span>
      <span class="action" (click)="closeTool()"><i class="icon square fa-regular fa-circle-xmark"></i></span>
    </div>
    <p class="note">Something</p>
    <form class="form" #shareForm="ngForm">
      <div class="field-row">
        <label class="wide">
          <span>Title<b>*</b></span>
          <input type="text" name="share-name" [(ngModel)]="forms.share.data.title" required/>
        </label>
      </div>
      <div class="field-row" *ngIf="toolData && (['discussion', 'question'].indexOf(toolData.type) > -1)">
        <label class="wide">
          <span>URL</span>
          <input type="text" name="share-url" [(ngModel)]="forms.share.data.url"/>
        </label>
      </div>
      <div class="field-row" *ngIf="toolData && (['media', 'news'].indexOf(toolData.type) > -1)">
        <label class="wide">
          <span>URL<b>*</b></span>
          <input type="text" name="share-url" [(ngModel)]="forms.share.data.url" required/>
        </label>
      </div>
      <div class="field-row" *ngIf="!toolData || (['news'].indexOf(toolData.type) > -1)">
        <label class="wide">
          <span>Text<em>Markdown Enabled</em></span>
          <textarea name="share-text" [(ngModel)]="forms.share.data.text"></textarea>
        </label>
      </div>
      <div class="field-row" *ngIf="toolData && (['discussion', 'question'].indexOf(toolData.type) > -1)">
        <label class="wide">
          <span>Question<b>*</b><em>Markdown Enabled</em></span>
          <textarea  name="share-text" [(ngModel)]="forms.share.data.text" required></textarea>
        </label>
      </div>
    </form>
    <div class="buttons">
      <span class="button close mobileonly" (click)="closeTool(); hideToolMenu();">Close</span>
      <span class="button blue" (click)="shareThing()" [ngClass]="{disabled: !shareForm.form.valid}">Submit {{toolData?.type || 'Post'}}</span>
    </div>
  </div>
</div>
<div class="modal-wrapper" [ngClass]="{active: showTools && tool == 'redeem'}" *ngIf="authService.user">
  <div class="modal tiny">
    <span class="header with-note">
        <span class="title">
          Redeem a Gift
        </span>
      </span>
    <p class="note">
      Got a gift or promo code? Simply enter it below and click on "Redeem" to unlock your rewards!
    </p>
    <div class="form">
      <label [ngClass]="{dirty: forms.redeem.code, invalid: forms.redeem.error}">
        <input type="text" placeholder="DDDD-AAAA-RRRR-TTTT" [(ngModel)]="forms.redeem.code" />
      </label>
    </div>
    <div class="buttons">
      <span class="button close" (click)="closeTool(); hideToolMenu();">Close</span>
      <span class="button blue" (click)="redeemCode()" [ngClass]="{disabled: !forms.redeem.code}">Redeem</span>
    </div>
  </div>
</div>
<div class="modal-wrapper" [ngClass]="{active: showTools && tool == 'contact'}">
  <div class="modal small">
    <div class="header"><span class="title">What's Up?</span><span class="action" (click)="closeTool(); hideToolMenu()"><i class="icon square fa-regular fa-circle-xmark"></i></span></div>
    <p class="note">
      Have a question, issue, or idea? Let us know. You can also reach out <a href="https://discord.gg/pQWY3Z28tZ" target="_blank">via Discord</a>.
    </p>
    <div class="form">
      <label>
        <select [(ngModel)]="forms.contact.reason">
          <option>General Question</option>
          <option>Tech Issue or Bug</option>
          <option>Feature Suggestion</option>
          <option>Claim a Club or Team</option>
        </select>
      </label>
      <label *ngIf="!authService.user">
        <span>Email Address<b>*</b></span>
        <input type="email" [(ngModel)]="forms.contact.email" required />
      </label>
      <label>
        <span>Your Message<b>*</b></span>
        <textarea [(ngModel)]="forms.contact.message" required></textarea>
      </label>
    </div>
    <div class="buttons">
      <span class="button blue" (click)="submitContact()" [ngClass]="{disabled: !forms.contact.email || !forms.contact.message}">Submit</span>
    </div>
  </div>
</div>
<div class="modal-wrapper" [ngClass]="{active: showTools && tool == 'geolocation'}">
  <div class="modal small">
    <div class="header"><span class="title">Geolocation</span><span class="action" (click)="closeTool(); hideToolMenu()"><i class="icon square fa-regular fa-circle-xmark"></i></span></div>
    <p class="note">
      DartSweep uses your location to display relevant clubs and events. If your current location appears incorrect, you can either set a location in your profile or grant us permission to use your device's GPS for accurate location information.
    </p>
    <div class="form">
      <label class="setting-toggle" [ngClass]="{disabled:locationService.allowGPS}">
        <dl class="details">
          <dt class="title">Device GPS</dt>
          <dd>Show comments below content instead of the sidebar.</dd>
        </dl>
        <div class="switch" [ngClass]="{active: locationService.allowGPS}" (click)="locationService.getPosition()">
          <b></b>
        </div>
        <input [(ngModel)]="forms.location.gps" type="checkbox" name="settings-chatbelow"/>
      </label>
      <label class="setting-toggle disabled">
        <dl class="details">
          <dt class="title">Profile Address</dt>
          <dd>Use provided address from your Profile Settings.</dd>
        </dl>
        <div class="switch" [ngClass]="{active: authService.user?.address}">
          <b></b>
        </div>
      </label>
      <label class="setting-toggle disabled">
        <dl class="details">
          <dt class="title">IP Address</dt>
          <dd>Look up an approximate location using your ISP or Provider.</dd>
        </dl>
        <div class="switch active">
          <b></b>
        </div>
      </label>
    </div>
  </div>
</div>
<div id="booster-wrapper" [ngClass]="{open: showsticker, active: booster && booster.type}">
  <span class="meta animate__animated animate__fadeIn"><span class="nomobile">Click</span><span
    class="mobileonly-inline">Tap</span> To Open</span>
  <div class="booster-animation booster-interaction">
    <div class="sticker sticker-flip" *ngIf="booster && booster.type">
      <img src="/assets/stickers/{{booster.type}}.png"/>
      <span class="name">{{booster.description}}</span>
<!--      <span class="rarity">Common</span>-->
      <span class="close" (click)="closeBooster();"><i class="fa-regular fa-circle-xmark"></i></span>
    </div>
    <div class="booster-animation booster-flip">
      <div class="booster-animation booster-anim-jump">
        <div class="booster-animation booster-anim-float">
          <div class="booster-animation booster-anim-wiggle">
            <div class="booster-pack" (click)="openBooster()">
              <span class="foil"></span>
              <div class="body">
                <img src="assets/img/logo-retina.png" width="181" loading="lazy" />
                <b>STICKER PACK</b>
              </div>
              <span class="foil"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
