import { Component, OnInit } from '@angular/core';
import {BuildService} from "../../services/build.service";
import {Router} from "@angular/router";
import {ProductService} from "../../services/products.service";

@Component({
  selector: 'app-new-build',
  templateUrl: './new-build.component.html',
  styleUrls: ['./new-build.component.less']
})
export class NewBuildComponent implements OnInit {
  public blasters: any[] = [];
  public features: any[] = [];
  public form: any = {
    name: '',
    blaster: 'Other',
    category: 'Other',
    photoOne: '',
    photoTwo: '',
    photoThree: '',
    photoFour: '',
    description: '',
    url: '',
    fps: null,
    dps: null,
    rof: 'Single Shot',
    microcontroller: 'None',
    barrel: {
      diameter: null,
      length: null
    },
    plunger: {
      volume: null,
      spring: null
    },
    battery: {
      voltage: null,
      capacity: null,
      rating: null,
      cells: null,
    },
    stages : [
      {crush:null,motors:null,wheels:null,cage:null},
      {crush:null,motors:null,wheels:null,cage:null}
    ]
  };

  public errors: any = {};

  public busy = false;
  public errored = false;
  public advanced = false;

  constructor(public router: Router, public buildService: BuildService, public productService: ProductService) { }

  ngOnInit(): void {
    this.productService.getCategories().subscribe((res: any) => {
      this.blasters = res.items.blasters;
      this.features = res.items.features;

      // this.blasters.sort(function(a, b) {
      //   return a.name.localeCompare(b.name);
      // });
    });
  }

  submit(): void {
    this.busy = true;

    this.buildService.createBuild(this.form).subscribe((res: any)=>{
      this.busy = false;
      // If res.status == 200, it's good, otherwise error
      if (res.status == 200) {
        if (res.build) {
          this.router.navigate(['/builds/' + res.build._id])
        }
      } else {
        this.errors = res.errors;
        this.errored = true;

        let masthead  = document.getElementById('primary-nav');
        if (masthead) {
          masthead.scrollIntoView();
        }
      }
    }, (err: any)=>{
      this.busy = false;
      this.errored = true;
      this.errors.server = true;

      let masthead  = document.getElementById('primary-nav');
      if (masthead) {
        masthead.scrollIntoView();
      }
    });
  }

  public onBlasterChange(event: any){
    if (event != 'Other') {
      this.blasters.forEach((blaster: any)=>{
        if (blaster.manufacturer + ' - ' + blaster.name == event) {
          if (blaster.category) {
            this.form.category = blaster.category;
          }
        }
      });
    }
  }

  public onFileSelected(key: any, event: any) {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      this.resizeBase64Img(e.target.result, 800, 800).then((result)=>{
        this.form[key] = result;
      });
    };
  }

  public resizeBase64Img(base64: string, newWidth: any, newHeight: any) {
    return new Promise((resolve, reject)=>{
      const canvas = document.createElement('canvas');

      canvas.height = newHeight;
      canvas.width = newWidth;

      let context = canvas.getContext('2d');
      let img = document.createElement('img');

      if (context) {
        img.src = base64;
        img.onload = function () {
          let hRatio = newWidth  / img.width    ;
          let vRatio =  newHeight / img.height  ;
          let ratio  = Math.min( hRatio, vRatio );

          if (context) {
            if (img.width < img.height) {
              context.canvas.width = img.width * ratio;
            } else {
              context.canvas.height = img.height * ratio;
            }

            let centerShift_x = ( context.canvas.width - img.width * ratio ) / 2;
            let centerShift_y = ( context.canvas.height - img.height * ratio ) / 2;

            context.drawImage(img, 0,0, img.width, img.height, centerShift_x,centerShift_y,img.width*ratio, img.height*ratio)
            resolve(canvas.toDataURL());
          } else {
            resolve(base64);
          }
        }
      } else {
        resolve(base64);
      }
    });
  }
}
