import { Pipe, PipeTransform } from '@angular/core';
import {AuthService} from "../services/auth.service";

@Pipe({name: 'convert'})
export class ConvertPipe implements PipeTransform {
  constructor(public authService: AuthService) {

  }
  transform(value: any, unit: string): string {
    if (this.authService.user) {
      if (unit == 'miles' && this.authService.user.settings?.metricdistance) {
        value = (value * 1.609).toFixed(0);
        unit = 'km';
      }

      if (unit == 'fps' && this.authService.user.settings?.metricvelocity) {
        value = (value / 3.281).toFixed(0);
        unit = 'mps';
      }
    }

    if (value > 999) {
      value = (value / 1000).toFixed(2) + 'k'
    }

    return value + ' ' + unit;
  }
}
