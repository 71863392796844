import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getCategories() {
    return this.http.get(environment.endpoint + '/products/categories');
  }

  getProduct(id: string) {
    return this.http.get(environment.endpoint + '/products/' + id);
  }

  getProducts(sort: any, filters: any, limit: number = 4, skip: number = 0) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    if (sort) {
      filter += '&sort=' + sort;
    }

    if (filters) {
      filter += '&filters=' + JSON.stringify(filters);
    }

    return this.http.get(environment.endpoint + '/products' + filter);
  }

  getProductReactions(id: string) {
    return this.http.get(environment.endpoint + '/products/' + id + '/reactions');
  }

  editProduct(id:string, data: any) {
    return this.http.put(environment.endpoint + '/products/' + id, data);
  }

  runProductAction(id:string, action: string, data:any) {
    return this.http.post(environment.endpoint + '/products/' + id + '/' + action, data);
  }
}
