<div class="post-wrapper wide padding-top-20">
  <div class="onboarding card">
    <div class="post-header with-actions">
            <span class="user-photo">
              <span class="initials">🎉</span>
            </span>
      <div class="details">
        <span class="h1">Welcome to DartSweep</span>
        <span class="meta">Take a quick tour below to get started, and earn a special profile sticker designed by Hotkoin.</span>
      </div>
      <ul class="buttons">
        <li class="button grey icon">
          <i class="fa-solid fa-forward-step"></i> SKIP
        </li>
      </ul>
    </div>
    <ul class="onboarding-steps">
      <li class="step">
        <div class="step-header">
                <span class="checkmark">
                  <i class="icon fa-solid fa-circle-check"></i>
                </span>
          <div class="details">
            <span class="title">Explore the Community</span>
            <span class="meta">See what players are sharing.</span>
          </div>
          <i class="icon action fa-solid fa-arrow-right"></i>
        </div>
        <div class="step-thumbnail">

        </div>
      </li>
      <li class="step">
        <div class="step-header">
                <span class="checkmark">
                  <i class="icon fa-solid fa-circle-check"></i>
                </span>
          <div class="details">
            <span class="title">Find Your Nearest Club</span>
            <span class="meta">Check out your nearby groups.</span>
          </div>
        </div>
        <div class="step-thumbnail">

        </div>
      </li>
      <li class="step">
        <div class="step-header">
                <span class="checkmark">
                  <i class="icon fa-solid fa-circle-check"></i>
                </span>
          <div class="details">
            <span class="title">Stand out from the crowd</span>
            <span class="meta">Add a Profile Picture</span>
          </div>
        </div>
        <div class="step-thumbnail">

        </div>
      </li>
    </ul>
  </div>
</div>
