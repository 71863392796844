import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.less']
})
export class PhotoComponent implements OnInit {
  @Input() src: string = '';
  @Input() width: string | undefined;

  public options = '';
  public excluded = false;
  public exclude = [
    'cdn/shop/',
    'shopify.com',
    'dartzoneblasters.com'
  ];

  constructor() { }

  ngOnInit(): void {
    let options: any = [
      'format=auto',
      'quality=100',
      'fit=scale-down',
      'width=' + (this.width || 800)
    ];

    this.options = options.join(',');

    if (this.exclude.some((value)=>{return this.src.indexOf(value) > -1})) {
      this.excluded = true;
    }
  }

}
