import { Component, OnInit } from '@angular/core';
import {LocationService} from "../../services/location.service";
import {ClubService} from "../../services/club.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-club',
  templateUrl: './new-club.component.html',
  styleUrls: ['./new-club.component.less']
})
export class NewClubComponent implements OnInit {
  public focuses: any[] = [
    'General',
    'Modding',
    'LARP',
    'Competitive',
    'HvZ',
    'Social',
    'Venue'
  ];

  public form = {
    name: '',
    tag: '',
    focus: '',
    url: '',
    description: '',
    country: '',
    region: '',
    regionname: '',
    location: '',
    application: ''
  };

  public errors: any = {};

  public busy = false;
  public errored = false;

  constructor(public router: Router, public locationService: LocationService, public clubService: ClubService) { }

  ngOnInit(): void {
  }

  submit(): void {
    this.busy = true;
    this.errors = {};

    this.clubService.createClub(this.form).subscribe((res: any)=>{
      this.busy = false;
      if (res.status == 200) {
        if (res.club) {
          this.router.navigate(['/clubs/' + res.club._id]);
        }
      } else {
        this.errors = res.errors;
        this.errored = true;

        let masthead  = document.getElementById('primary-nav');
        if (masthead) {
          masthead.scrollIntoView();
        }
      }
    }, (err)=>{
      this.errored = true;
      this.busy = false;

      let masthead  = document.getElementById('primary-nav');
      if (masthead) {
        masthead.scrollIntoView();
      }
    });
  }

}
