<li *ngIf="post" class="card view-{{view}} no-padding">
  <div class="card-header">
    <div class="user" *ngIf="post.source">
      <span class="user-photo">
        <img src="assets/img/source-{{post.source.replaceAll(' ','').toLowerCase()}}.png" alt="{{post.author.source}}" alt="{{post.author.source}}'s Logo" loading="lazy" />
      </span>
      <div class="details">
        {{post.source || post.author.handle || post.author.name}}
        <span class="meta">
          <i class="fa-solid fa-hashtag"></i>{{post.type}}
        </span>
      </div>
    </div>
    <div class="user" *ngIf="!post.source">
      <span class="user-photo" routerLink="/players/{{post.author._id}}">
        <img *ngIf="post.author.photo" src="{{post.author.photo}}" alt="{{post.author.name}}'s Photo" loading="lazy" />
        <span *ngIf="!post.author.photo" class="initials">{{post.author.name | initials}}</span>
      </span>
      <div class="details">
        <app-handle [player]="post.author"></app-handle>
        <span class="meta">
          <i class="fa-solid fa-hashtag"></i>{{post.type}}
        </span>
      </div>
    </div>
    <ul class="actions">
<!--      <li class="button ghost mini">-->
<!--        <i class="icon fa-solid fa-ellipsis"></i>-->
<!--      </li>-->
    </ul>
  </div>
  <div class="card-banner interactable"  [ngClass]="{'embed-preview':post.embed}" routerLink="/posts/{{post._id}}" [ngStyle]="{'background-image':(post.embed?.lqip ? 'url(' + post.embed?.lqip + ')' : null)}">
    <app-photo *ngIf="post.photo && (!post.embed || !post.embed.thumbnail)" [src]="post.photo" width="270"></app-photo>
    <app-photo *ngIf="post.embed && post.embed.thumbnail" [src]="post.embed?.thumbnail" width="270"></app-photo>
    <span *ngIf="!post.photo && !post.embed?.thumbnail">
      <i class="fa-solid {{typeMap[post.type] || 'fa-circle-question'}}"></i>
    </span>
    <i *ngIf="post.external && post.url" class="icon fa-solid fa-arrow-up-right-from-square"></i>
  </div>
  <div class="card-body">
    <a class="title interactable" href="/posts/{{post._id}}" routerLink="/posts/{{post._id}}">{{post.title}}</a>
    <p class="interactable" routerLink="/posts/{{post._id}}" *ngIf="view == 'grid' || (view=='list' && !post.url)">{{post.embed?.description || post.summary | truncate:[80, '...']}}</p>
    <a class="link" href="{{post.url}}" target="_blank" *ngIf="post.url">{{post.url}}</a>
  </div>
  <ul class="card-footer">
    <li [ngClass]="{highlighted:post.messages > 0}">
      <i class="fa-solid fa-message"></i>
      <b>{{post.messages || 0 | shortNumber}}</b>
    </li>
    <li class="counter-heat" [ngClass]="{highlighted:post.temp > 0}">
      <i class="fa-solid fa-fire"></i>
      <b>{{post.temp | temp}}º</b>
    </li>
    <li class="anchored small">
<!--      <i class="fa-solid fa-check"></i>-->
      {{(post.date || post.createdAt) | timeAgo}}
    </li>
  </ul>
</li>
