import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import {AuthService} from "./auth.service";
import {ApplicationService} from "../app.service";

// var pusher = new Pusher("app_key");
// pusher.connection.bind("error", function (err) {
//   if (err.error.data.code === 4004) {
//     log(">>> detected limit error");
//   }
// });

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  public pusher: any = null;

  private channels:any = {};
  private user: any = null;

  private binds: any = [];

  constructor(public authService: AuthService, public appService: ApplicationService) {
    if (this.appService.isBrowser) {
      this.pusher = new Pusher('3c07095675ee5edb3862', {
        cluster: 'us3',
        userAuthentication: {
          endpoint: 'https://api.dartsweep.io/hooks/pusher',
          transport: 'ajax',
          headersProvider: () => {
            return {
              Authorization: `Bearer ${this.authService.jwt}`
            };
          }
        }
      });
      this.pusher.bind_global((event: any, data: any) => {
        if (this.binds[event]) {
          this.binds[event](data);
        }

        if (event == 'pusher:signin_success') {
          if (!this.user) {
            this.user = this.pusher.user.bind_global(function (type: string, data: any) {
              // console.log('USER DATA', type, data);
            });
          }
        }
      });
    }
  }

  init(){
    if (!this.pusher) {

    }
  }

  openChannel(key: string, bind: any){
    // if (this.authService.jwt) {
    //   this.pusher.signin();
    // }

    if (this.pusher) {
      this.channels[key] = this.pusher.subscribe(key);
      this.channels[key].bind_global((type: string, data: any) => {
        if (bind) {
          bind(type, data);
        }
      });
    }

  }

  closeChannel(key: string){
    if (this.channels[key]) {
      this.channels[key].unbind_all();
      this.pusher.unsubscribe(this.channels[key]);
    }
  }

  bindEvent(type: string, callback: any){
    if (this.binds[type]) {
      return;
    }

    this.binds[type] = callback;
  }
}
