<li *ngIf="product" class="card view-grid no-padding animate__animated animate__fadeIn">
  <div class="card-header">
    <div class="user">
      <span class="user-photo" routerLink="/players/{{product.seller._id}}">
        <img *ngIf="product.seller.photo" src="{{product.seller.photo}}" alt="{{product.seller.name}}"/>
        <span *ngIf="!product.seller.photo" class="initials">{{product.seller.name | initials}}</span>
      </span>
      <div class="details">
        <app-handle [player]="product.seller"></app-handle>
        <span class="meta">
          <i class="fa-solid fa-hashtag"></i>{{product.category ? product.category.toLowerCase() : 'product'}}
        </span>
      </div>
    </div>
  </div>
  <a class="card-banner interactable" routerLink="/market/{{product._id}}" [ngStyle]="{'background-image':(product.embed?.lqip ? 'url(' + product.embed?.lqip + ')' : null)}">
    <app-photo *ngIf="product.embed?.thumbnail || product.photo" [src]="product.embed?.thumbnail || product.photo" width="270"></app-photo>
  </a>
  <div class="card-body">
    <a class="title interactable" routerLink="/market/{{product._id}}">{{product.title}}</a>
    <p class="interactable">{{product.price | currency:product.currency}}</p>
    <a class="link" routerLink="/market/{{product._id}}" *ngIf="product.url">{{product.embed?.url || product.url}}</a>
  </div>
  <ul class="card-footer">
    <li [ngClass]="{highlighted:product.messages > 0}">
      <i class="fa-solid fa-message"></i>
      <b>{{product.messages || 0 | shortNumber}}</b>
    </li>
    <li class="counter-heat" [ngClass]="{highlighted:product.temp > 0}">
      <i class="fa-solid fa-fire"></i>
      <b>{{product.temp | temp}}º</b>
    </li>
    <li class="anchored small">
      <!--      <i class="fa-solid fa-check"></i>-->
      {{(product.createdAt) | timeAgo}}
    </li>
  </ul>
</li>
