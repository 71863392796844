<li *ngIf="build" class="card view-{{view}} no-padding animate__animated animate__fadeIn">
  <div class="card-header">
    <div class="user">
      <span class="user-photo" routerLink="/players/{{build.owner._id}}">
        <img *ngIf="build.owner.photo" src="{{build.owner.photo}}" alt="{{build.owner.name}}"/>
        <span *ngIf="!build.owner.photo" class="initials">{{build.owner.name | initials}}</span>
      </span>
      <div class="details">
        <app-handle [player]="build.owner"></app-handle>
        <span class="meta">
          <i class="fa-solid fa-hashtag"></i>{{build.blaster?.name.toLowerCase() || 'build' | replace:' ':''}}
        </span>
      </div>
    </div>
  </div>
  <div class="card-banner interactable" routerLink="/builds/{{build._id}}" [ngStyle]="{'background-image':(build.thumbnail ? 'url(' + build.thumbnail + ')' : null)}">
    <app-photo *ngIf="build.photos.length" [src]="build.photos[0]" width="270"></app-photo>
  </div>
  <div class="card-body">
    <a class="title interactable" href="/builds/{{build._id}}" routerLink="/builds/{{build._id}}">{{build.name}}</a>
    <p class="interactable" routerLink="/builds/{{build._id}}" *ngIf="view == 'grid' || (view=='list' && !build.url)">{{build.description | truncate:[80, '...']}}</p>
    <a class="link" href="{{build.url}}" target="_blank" *ngIf="build.url">{{build.url}}</a>
  </div>
  <ul class="card-footer">
    <li [ngClass]="{highlighted:build.messages > 0}">
      <i class="fa-solid fa-message"></i>
      <b>{{build.messages || 0 | shortNumber}}</b>
    </li>
    <li class="counter-heat" [ngClass]="{highlighted:build.temp > 0}">
      <i class="fa-solid fa-fire"></i>
      <b>{{build.temp | temp}}º</b>
    </li>
    <li class="anchored small">
      <!--      <i class="fa-solid fa-check"></i>-->
      {{(build.createdAt) | timeAgo}}
    </li>
  </ul>
</li>
