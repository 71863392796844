import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, Observer, Subscription} from "rxjs";
import {filter, share} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  observable: Observable<any>;
  observer: Observer<any> | undefined;
  private dataStorage: any = {};

  constructor(private http: HttpClient) {
    this.observable = Observable.create((observer: Observer<any>) => {
      this.observer = observer;
    }).pipe(share());
  }

  set(key: string, data: any) {
    this.dataStorage[key] = data;
  }

  get(key: string) {
    return this.dataStorage[key];
  }

  clear(key: string) {
    this.dataStorage[key] = undefined;
  }

  getStats(){
    return this.http.get(environment.endpoint + '/data/stats.json');
  }

  subscribe(eventName: string, callback: any) {
    const subscriber: Subscription = this.observable
      .pipe(
        filter(event => {
          return event.name === eventName;
        })
      ).subscribe(callback);
    return subscriber;
  }

  broadcast(event: any) {
    if (this.observer != null) {
      this.observer.next(event);
    }
  }

  destroy(subscriber: Subscription) {
    subscriber.unsubscribe();
  }
}
