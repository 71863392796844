import {
  AfterViewInit,
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  OnDestroy,
  HostListener, ChangeDetectionStrategy
} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {AuthService} from "./services/auth.service";
import {LocationService} from "./services/location.service";
import {
  Router,
  Event,
  NavigationEnd,
} from "@angular/router";

import {environment} from '../environments/environment';
import {DataService} from "./services/data.service";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {SearchService} from "./services/search.service";
import {NotificationService} from "./services/notifications.service";

// @ts-ignore
import * as confetti from 'canvas-confetti';
import * as dayjs from 'dayjs';
import {HotkeysService} from "./services/hotkeys.service";
import {ApplicationService} from "./app.service";
import {PostService} from "./services/post.service";
import {MarkdownService} from "ngx-markdown";
import {PusherService} from "./services/pusher.service";
import {ToastService} from "./services/toast.service";
import {ConversationsService} from "./services/conversations.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  @ViewChild('scrollWrapper') scrollWrapper: ElementRef | undefined;
  @ViewChild('searchBar') searchBar: ElementRef | undefined;

  @HostListener('window:scroll', ['$event']) onScrollEvent($event: any){
    let offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (offset > 800) {
      this.showScrollReset = true;
    } else {
      this.showScrollReset = false;
    }
  }

  public buildId = environment.appVersion || 'Unknown';
  public search = new Subject();
  public searchText = '';
  public searchResults: any[] = [];
  public searchBusy = false;
  public alerts: any[] = [];
  public booster: any = null;
  public notifications: any[] = [];
  public conversations: any[] = [];
  public unread = 0;
  public network = 'dartsweep';
  public tool = '';
  public toolData: any = {};
  public showTools = false;
  public showMoreTools = false;
  public showScrollReset = false;
  public showMenu = false;
  public showsearch = false;
  public showsticker = false;
  public showbooster = true;
  public showconversations = false;

  public route = '';
  public nav: any = {
    blasters: false,
    builds: false
  };

  public forms = {
    signin: {
      visible: false,
      busy: false,
      signup: false,
      step: 1,
      data: {
        email: '',
        name: '',
        handle: '',
        token: [null, null, null, null, null, null],
      },
      errors: {
        name: null,
        email: null,
        handle: null
      }
    },
    redeem: {
      code: '',
      error: ''
    },
    contact: {
      reason: 'General Question',
      email: '',
      message: '',
    },
    location: {
      gps: false
    },
    share: {
      busy: false,
      data: {
        title: '',
        url: '',
        text: ''
      },
      errors: {}
    }
  };

  public focusMap: any = {
    'General': 'fa-crosshairs',
    'Modding': 'fa-screwdriver-wrench',
    'LARP': 'fa-dice-d20',
    'Competitive': 'fa-fire',
    'HvZ': 'fa-biohazard',
    'Social': 'fa-comment',
    'Venue': 'fa-city',
  };

  public assemblyMap: any = {
    '3D Printed': 'fa-cube',
    'Injection Molded': 'fa-truck',
    'Machined': 'fa-industry',
    'Homemade': 'fa-box',
  };

  public alertMap: any = {
    'like': 'fa-heart',
    'join': 'fa-user-plus',
    'approve': 'fa-thumbs-up',
    'deny': 'fa-ban',
    'sync': 'fa-arrows-rotate',
    'bug': 'fa-bug',
    'report': 'fa-flag',
    'sticker': 'fa-note-sticky',
  };

  public toastMap: any = {
    'security': 'fa-shield-halved'
  };

  public locationSourceMap: any = {
    'gps': 'fa-location-crosshairs',
    'ip': 'fa-wifi',
    'user': 'fa-location-dot',
  };

  private intervals: any = {
    alerts: null
  };

  constructor(private meta: Meta, private renderer2: Renderer2, private markdownService: MarkdownService, private elementRef: ElementRef, private hotkeys: HotkeysService, public toastService: ToastService, public pusherService: PusherService, public appService: ApplicationService, public authService: AuthService, public conversationService: ConversationsService, public postService: PostService, public locationService: LocationService, public notificationService: NotificationService, public searchService: SearchService, public dataService: DataService, public router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.route = event.url.split('/')[1];
        if (this.nav.hasOwnProperty(this.route)) {
          this.nav[this.route] = true;
        }

        this.showMenu = false;
        this.showsearch = false;

        this.hideToolMenu();
        this.triggerScrollReset();

        this.meta.updateTag({property: 'og:url', content: environment.host + event.url})
      }
    });

    this.search.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe((value: any) => {
        if (value) {
          this.showsearch = true;
          this.fetchSearchResults(true);
        } else {
          this.showsearch = false;
          this.searchResults = [];
        }
      });

    if (this.appService.isBrowser) {
      this.pusherService.init();
      this.authService.reauthorize();
      this.authService.authEvent.subscribe((event) => {
        if (event == 'auth:success') {
          if (this.authService.user.location && this.authService.user.location.coordinates.length) {
            this.locationService.setLocation([this.authService.user.location.coordinates[1], this.authService.user.location.coordinates[0]], {label: this.authService.user.address, source: 'user'});
          }
          this.forms.contact.email = authService.user._id;
          this.getNotifications();
          this.getConversations();
          this.pusherService.pusher.signin();
          this.pusherService.bindEvent('notifications:new', (data: any) => {
            if (data && data.payload && data.payload.notification) {
              this.processNotification(data.payload.notification, true);
            }
          });

          this.pusherService.bindEvent('conversations:updated', (data: any) => {
            if (data && data.payload) {
              this.getConversations();
            }
          });
        }
      });

      this.locationService.locate();

      this.dataService.subscribe('scrollReset', ()=>{
        this.triggerScrollReset();
      });

      this.dataService.subscribe('showContact', (data: any)=>{
        if (data && data.reason) {
          this.forms.contact.reason = data.reason;
        }

        this.openTool('contact');
      });

      this.intervals.alerts = setInterval(() => {
        if (this.booster) {
          this.booster.timer--;

          if (this.booster.timer == 0) {
            this.openBooster();
          }
        }
      }, 1000);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervals.alerts);
  }

  getConversations() {
    if (this.authService.user && this.authService.user._id) {
      this.conversationService.getConversations().subscribe((res: any) => {
        if (res && res.items) {
          this.conversations = res.items;
          this.updateNotifications();
        }
      });
    }
  }

  getNotifications() {
    if (this.authService.user && this.authService.user._id) {
      this.notificationService.getAllNotifications().subscribe((res: any) => {
        if (res && res.items) {
          this.notifications = [];

          res.items.forEach((notification: any) => {
            this.processNotification(notification);
          });
        }
      });
    }
  };

  processNotification(notification: any, prepend = false) {
    notification.parsed = this.markdownService.parse(notification.text);

    if (prepend) {
      this.notifications.unshift(notification);
    } else {
      this.notifications.push(notification);
    }

    this.updateNotifications();

    if (notification.type == 'sticker' && notification.data && !notification.read) {
      let sticker = notification.data;
      sticker.id = notification._id;
      this.showBooster(sticker);
    } else {
      let now = dayjs().subtract(30, 'seconds');
      if (dayjs(notification.createdAt).isAfter(now)) {
        const existing = this.alerts.map((alert) => alert._id);

        if (!existing.includes(notification._id)) {
          notification.timer = 5;
          this.alerts.push(notification);
        }
      }
    }
  }

  dismissNotification(notification: any) {
    this.notificationService.markRead(notification._id).subscribe(() => {
      notification.read = true;
      this.updateNotifications();
    });
  }

  updateNotifications() {
    this.unread = this.notifications.filter(x => x.read == false).length;
    this.unread += this.conversations.length;
  }

  dismissAlert() {
    this.notificationService.markRead(this.alerts[0]._id).subscribe(() => {
      this.alerts.shift();
    });
  }

  submitContact(){
    this.authService.submitContact(this.forms.contact).subscribe((res: any) => {
      if (res.toast) {
        this.toastService.addToast(res.toast.type, res.toast.title, res.toast.text);
      }

      this.hideToolMenu();
    });
  }

  redeemCode() {
    this.authService.redeemGift(this.forms.redeem.code).subscribe((res: any) => {
      if (res.items) {
        this.getNotifications();
        this.hideToolMenu();
      } else {
        this.forms.redeem.error = res.error || 'Unknown Error';
      }
    });
  }

  shareThing() {
    // if (['post', 'question', 'media','news'].indexOf(this.toolData.type) > -1) {
      this.forms.share.busy = true;
      this.forms.share.errors = {};

      this.postService.createPost(this.toolData.type, this.forms.share.data).subscribe((res: any) => {
        this.forms.share.busy = false;
        if (res.status == 200) {
          if (res.post) {
            this.router.navigate(['/posts/' + res.post._id])
          }
        } else {
          this.forms.share.errors = res.errors;
          let masthead = document.getElementById('primary-nav');
          if (masthead) {
            masthead.scrollIntoView();
          }
        }
      }, () => {
        this.forms.share.busy = false;
        let masthead = document.getElementById('primary-nav');
        if (masthead) {
          masthead.scrollIntoView();
        }
      });
    // }
  }

  signIn() {
    this.forms.signin.busy = true;
    if (this.forms.signin.signup) {
      this.authService.signUp(this.forms.signin.data.name, this.forms.signin.data.email, this.forms.signin.data.handle).subscribe((res: any) => {
        this.forms.signin.busy = false;

        if (res.success) {
          this.forms.signin.errors.name = null;
          this.forms.signin.errors.email = null;
          this.forms.signin.errors.handle = null;
          this.forms.signin.step = 2;
        } else {
          if (res.error) {
            this.forms.signin.errors.name = res.error.fields.name || null;
            this.forms.signin.errors.email = res.error.fields.email || null;
            this.forms.signin.errors.handle = res.error.fields.handle || null;
          }
        }
      });
    } else {
      this.authService.requestToken(this.forms.signin.data.email).subscribe((res: any) => {
        this.forms.signin.busy = false;
        if (res.success) {
          if (res.register) {
            this.forms.signin.signup = true;
          } else {
            this.forms.signin.signup = false;
            this.forms.signin.step = 2;
          }
        }
      });
    }
  }

  verifyToken() {
    this.authService.verifyToken(this.forms.signin.data.email, this.forms.signin.data.token.join('')).subscribe((res: any) => {
      if (res.jwt) {
        this.authService.setJWT(res.jwt);
        this.forms.signin.busy = false;
        this.forms.signin.visible = false;
      }
    });
  }

  twoFactorPaste(event?: any){
    event.preventDefault();

    let paste = event.clipboardData.getData('text');
    if (paste) {
      this.forms.signin.data.token = paste.split('');
      this.verifyToken();
    }
  }

  twoFactorEntry(event?: any) {
    if (!isFinite(event.key) && event.key !== 'Backspace') {
      return;
    }

    let next = event.key == 'Backspace' ? event.srcElement.previousSibling : event.srcElement.nextElementSibling;
    if (next) {
      next.focus();
      if (event.key == 'Backspace') {
        next.value = '';
      }
    } else {
      this.verifyToken();
    }
  }

  showSignIn() {
    this.forms.signin.visible = true;
    this.showMenu = false;
  }

  cancelSignIn(event?: any) {
    if (event) {
      if (event.target.classList.contains('modal-wrapper')) {
        if (this.forms.signin.signup) {
          this.forms.signin.signup = false;
        } else {
          this.forms.signin.visible = false;
        }
      }
    } else {
      if (this.forms.signin.signup) {
        this.forms.signin.signup = false;
      } else {
        this.forms.signin.visible = false;
      }
    }
  }

  toggleNavItem(items: string | string[]) {
    if (typeof items == 'object') {
      let state = true;
      if (items.some((item) => {
        return this.nav[item] == true;
      })) {
        state = false;
      }

      items.forEach((item) => {
        this.nav[item] = state;
      });
    } else {
      this.nav[items] = !this.nav[items];
    }
    // if (typeof item == 'array') {
    //
    // }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.showsearch = false;
  }

  toggleSearch() {
    this.showsearch = !this.showsearch;
    this.showMenu = false;

    if (this.showsearch) {
      setTimeout(() => {
        this.searchBar?.nativeElement.focus();
      }, 250);
    }
  }

  showToolMenu() {
    this.showTools = true;
    this.tool = '';
  }

  hideToolMenu() {
    this.showTools = false;
  }

  toggleMoreTools() {
    this.showMoreTools = !this.showMoreTools;
  }

  openTool(tool: string, data: any = null) {
    this.showTools = true;
    this.tool = tool;
    this.toolData = data;
  }

  closeTool() {
    this.tool = '';
    this.toolData = null;
  }

  fetchSearchResults(reset = false) {
    let skip = this.searchResults.length;
    this.searchBusy = true;

    if (this.searchText) {
      let skip = reset ? 0 : this.searchResults.length;

      this.searchService.search(this.searchText, 9, skip).subscribe((res: any) => {
        if (reset) {
          this.searchResults = [];
        }

        res.items.forEach((item: any) => {
          if (this.locationService.location && item.coordinates.length) {
            item.distance = this.locationService.calcDistance(this.locationService.location, {
              lat: item.coordinates[1],
              lon: item.coordinates[0]
            })
          }

          this.searchResults.push(item);
        });

        this.searchBusy = false;
      });
    }
  }

  showBooster(sticker: any) {
    if (this.booster) {
      return;
    }

    this.showsticker = false;
    this.booster = {
      id: sticker.id,
      type: sticker.type,
      name: sticker.name,
      description: sticker.description,
      rarity: sticker.common || 'Common',
      timer: 5
    };
  }

  openBooster() {
    this.notificationService.markRead(this.booster.id).subscribe((res) => {
      const canvas = this.renderer2.createElement('canvas');

      this.showsticker = true;
      this.renderer2.appendChild(this.elementRef.nativeElement, canvas);

      confetti.create(canvas, {resize: true})();
    })
  }

  closeBooster() {
    this.booster = null;
  }

  triggerScrollReset(){
    if (this.appService.isBrowser) {
      window?.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      this.scrollWrapper?.nativeElement.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
