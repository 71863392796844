import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  getNotifications() {
    return this.http.get(environment.endpoint + '/notifications');
  }

  getAllNotifications(){
    return this.http.get(environment.endpoint + '/notifications?all=true');
  }

  markRead(id: any){
    return this.http.put(environment.endpoint + '/notifications/' + id, {read: true});
  }
}
