<ng-container *ngIf="player">
  <a class="handle" routerLink="/players/{{player._id}}"><i class="fa-solid fa-at"></i>{{player.handle || player.name.toLowerCase()}}</a>
  <span class="badges">
    <i *ngIf="player.verified" class="badge-verified fa-solid fa-circle-check"></i>
    <i *ngIf="player.staff" class="badge-moderator fa-solid fa-shield-halved"></i>
    <i *ngIf="player.handle == 'api'" class="badge-bot fa-solid fa-robot"></i>
      <!--  <i *ngIf="player.pro" class="badge-pro fa-solid fa-certificate"></i>-->
  </span>
</ng-container>
<ng-container *ngIf="club">
  <a class="handle" routerLink="/clubs/{{club._id}}"><i class="fa-solid fa-at"></i>{{club.tag}}</a>
  <span class="badges">
    <i *ngIf="club.verified" class="badge-verified fa-solid fa-circle-check"></i>
      <!--  <i *ngIf="player.pro" class="badge-pro fa-solid fa-certificate"></i>-->
  </span>
</ng-container>
