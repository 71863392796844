import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private http: HttpClient) { }

  search(term: string) {
    return this.http.get(environment.endpoint + '/teams/search/' + term.trim());
  }

  getTeam(id: string) {
    return this.http.get(environment.endpoint + '/teams/' + id);
  }

  getTeams(sort: any, filters: any, limit: number = 4, skip: number = 0) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    if (sort) {
      filter += '&sort=' + sort;
    }

    if (filters) {
      filter += '&filters=' + JSON.stringify(filters);
    }

    return this.http.get(environment.endpoint + '/teams' + filter);
  }

  getTeamGames(id: string) {
    return this.http.get(environment.endpoint + '/teams/' + id + '/games');
  }

  getTeamMatches(id: string) {
    return this.http.get(environment.endpoint + '/teams/' + id + '/matches');
  }

  createTeam(data: any){
    return this.http.post(environment.endpoint + '/teams', data);
  }

  editTeam(id:string, data: any) {
    return this.http.put(environment.endpoint + '/teams/' + id, data);
  }

  runTeamAction(id:string, action: string, data:any) {
    return this.http.post(environment.endpoint + '/teams/' + id + '/' + action, data);
  }
}
