

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'mentions'})
export class MentionsPipe implements PipeTransform {
  transform(text: string): string {
    // let matches = text.match(/(?<=\<)(.*?)(?=\>)/);
    let matches = text.match(/\|(.*?)\|/g);

    if (matches) {
      matches.forEach((mention)=>{
        const ats = ['player', 'club'];
        let parsed = mention.replaceAll('|', '').split(':');
        let type = parsed[0];
        let display = parsed[2];

        if (type == 'player') {

        }

        let replacement = '[<i class="fa-solid ' + (ats.indexOf(type) > - 1 ? 'fa-at' : 'fa-hashtag') + '"></i>' + display + '](/' + parsed[0] + 's/' + parsed[1] + ')';

        text = text.replace('<' + mention + '>', replacement);
      });
    }

    return text;
  }
}
