import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(input: string | null, pattern: any, replacement: any): any {
    if (!input) {
      return input;
    }

    return input.toString().replaceAll(pattern, replacement);
  }
}
