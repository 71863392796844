<div id="view-new-league" class="post-wrapper">
  <header>
      <h1>Register a Team</h1>
      <div class="masthead">
        Whether you already have a stacked roster or you're actively recruiting, registering your team is the first step to hitting the field.
      </div>
  </header>
  <form #formCreateGroup="ngForm" class="padding-bottom-20">
    <span class="field-label">Basic Info</span>
    <div class="field-row">
      <label>
        <span>Team Name<b>*</b></span>
        <input [(ngModel)]="form.name" name="name" type="text" required/>
      </label>
      <label class="half">
        <span>Organization<b>*</b></span>
        <select [(ngModel)]="form.organization" name="organization" required>
          <option selected>Freelance</option>
          <ng-container *ngIf="organizations">
            <option *ngFor="let organization of organizations" value="{{organization._id}}">{{organization.name}}</option>
          </ng-container>
        </select>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>About Your Team</span>
        <textarea [(ngModel)]="form.description" name="description"></textarea>
      </label>
    </div>
    <hr />
    <span class="field-label">Team Colors</span>
    <div class="field-row">
      <label>
        <span>Primary</span>
        <input [(ngModel)]="form.primarycolor" name="primarycolor" type="color" required/>
      </label>
      <label>
        <span>Secondary</span>
        <input [(ngModel)]="form.secondarycolor" name="secondarycolor" type="color" required/>
      </label>
      <label>
        <span>Accent</span>
        <input [(ngModel)]="form.accentcolor" name="accentcolor" type="color" required/>
      </label>
    </div>
    <hr />
    <span class="field-label">Location</span>
    <div class="field-row">
      <label>
        <span>Country<b>*</b></span>
        <input [(ngModel)]="form.country" name="location" type="text" required/>
      </label>
    </div>
    <div class="field-row">
      <label>
        <span>City / Location<b>*</b><em class="error" *ngIf="errors && errors.location">{{errors.location}}</em></span>
        <input [(ngModel)]="form.location" name="location" type="text" required/>
      </label>
    </div>
    <hr/>
    <span class="button submit" [ngClass]="{disabled:formCreateGroup.invalid, busy: busy}" (click)="submit()">Register Team</span>
  </form>
</div>
