import { Component, OnInit } from '@angular/core';
import {BlasterService} from "../../services/blaster.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-blaster',
  templateUrl: './new-blaster.component.html',
  styleUrls: ['./new-blaster.component.less']
})
export class NewBlasterComponent implements OnInit {
  public form = {
    name: '',
    sku: '',
    url: '',
    purchase: '',
    description: '',
    manufacturer: '',
    released: '',
    assembly: '',
    printable: false
  };

  public errors: any = {};

  public busy = false;
  public errored = false;

  constructor(public router: Router, public blasterService: BlasterService) { }

  ngOnInit(): void {
  }

  submit(): void {
    this.busy = true;
    this.blasterService.createBlaster(this.form).subscribe((res: any)=>{
      this.busy = false;
      // If res.status == 200, it's good, otherwise error
      if (res.status == 200) {
        if (res.blaster) {
          this.router.navigate(['/blasters/' + res.blaster._id])
        }
      } else {
        let masthead  = document.getElementById('primary-nav');
        if (masthead) {
          masthead.scrollIntoView();
        }
      }
    }, (err: any)=>{
      this.busy = false;
      let masthead  = document.getElementById('primary-nav');
      if (masthead) {
        masthead.scrollIntoView();
      }
    });
  }

}
