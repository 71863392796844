<div class="post-wrapper wide">
  <div *ngIf="!authService.jwt" class="nomobile">
    <header>
      <div>
        <span class="h1">Explore DartSweep</span>
      </div>
    </header>
    <div>
      <div class="masthead">
        <div class="card" id="hero-intro">
          <div class="hero-textblock">
            <h1>Find Blaster Tag <span>Events</span><br/> and <span>Clubs</span> near you.</h1>
            <h2 class="nomobile">DartSweep is your hub for everything Blaster Tag. Connect with fellow foam flingers,
              share your builds, and keep tabs on the latest news and blaster releases.</h2>
            <div class="buttons">
              <span class="button" routerLink="/clubs">Find your Local Clubs</span>
              <span class="button outline" routerLink="/play">Browse Nearby Events</span>
            </div>
          </div>
          <div class="card-masonry nomobile animate__animated animate__fadeInDown" *ngIf="locationService.location">
            <div class="floating card card-map animate__animated animate__fadeIn" *ngIf="nearby.geoJSON">
              <img
                src="https://api.dartsweep.io/render/map?zoom=12&center={{locationService.location.lat}},{{locationService.location.lon}}&size=375x375&logo=topright&attributions=bottomright"/>
            </div>
            <div class="floating card-grid card-club nomobile" *ngIf="nearby.clubs.length">
              <app-card-club [club]="nearby.clubs[0]"></app-card-club>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="body">
      <ul class="tabs">
        <li>Latest and Trending</li>
      </ul>
      <ul class="card-list">
        <ng-container *ngFor="let post of latest.posts.slice(0, 4); let i = index">
          <app-card-post [post]="post" [view]="'list'"></app-card-post>
        </ng-container>
        <ng-container *ngIf="loading.posts">
          <li class="placeload type-post" *ngFor="let index of [0,1,2,3,4]">
            <span class="thumbnail mock"></span>
            <div class="details">
              <span class="user mock"></span>
              <b class="mock text header wide"></b>
              <b class="mock text wide"></b>
              <b class="mock text header"></b>
            </div>
          </li>
        </ng-container>
      </ul>
      <ul class="shelf card-grid margin-bottom-20 margin-top-20">
        <ng-container *ngFor="let build of trending.builds.slice(0, 4)">
          <app-card-build class="card" [build]="build"></app-card-build>
        </ng-container>
        <a class="floating-cta nomobile" href="/builds" routerLink="/builds"><i class="fa-solid fa-arrow-right"></i></a>
      </ul>
      <ul class="card-list">
        <ng-container *ngFor="let post of latest.posts.slice(4, 8)">
          <app-card-post [post]="post" [view]="'list'"></app-card-post>
        </ng-container>
      </ul>
      <ul class="card-grid margin-bottom-20 margin-top-20">
        <ng-container *ngFor="let build of latest.builds.slice(0, 4)">
          <app-card-build class="card" [build]="build"></app-card-build>
        </ng-container>
        <a class="floating-cta nomobile" href="/builds" routerLink="/builds"><i class="fa-solid fa-arrow-right"></i></a>
      </ul>
      <ul class="card-list">
        <ng-container *ngFor="let post of latest.posts.slice(8, 12)">
          <app-card-post [post]="post" [view]="'list'"></app-card-post>
        </ng-container>
      </ul>
      <ul class="card-grid margin-bottom-20 margin-top-20">
        <ng-container *ngFor="let product of latest.products.slice(0, 4)">
          <app-card-product class="card" [product]="product"></app-card-product>
        </ng-container>
        <a class="floating-cta nomobile" href="/market" routerLink="/market"><i class="fa-solid fa-arrow-right"></i></a>
      </ul>
      <ul class="card-list">
        <ng-container *ngFor="let post of latest.posts.slice(12)">
          <app-card-post [post]="post" [view]="'list'"></app-card-post>
        </ng-container>
        <li class="button outline wide" routerLink="/posts">View All Posts</li>
      </ul>
    </div>
  </div>
</div>
