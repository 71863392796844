import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  constructor(private http: HttpClient) { }

  getClub(id: string) {
    return this.http.get(environment.endpoint + '/clubs/' + id);
  }

  getClubs(sort: any, filters: any, limit: number = 4, skip: number = 0) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    if (sort) {
      filter += '&sort=' + sort;
    }

    if (filters) {
      filter += '&filters=' + JSON.stringify(filters);
    }

    return this.http.get(environment.endpoint + '/clubs' + filter);
  }

  getMembersByClub(id: string) {
    return this.http.get(environment.endpoint + '/clubs/' + id + '/members');
  }

  getEventsByClub(id: string) {
    return this.http.get(environment.endpoint + '/clubs/' + id + '/events');
  }

  createClub(data: any){
    return this.http.post(environment.endpoint + '/clubs', data);
  }

  editClub(id:string, data: any) {
    return this.http.put(environment.endpoint + '/clubs/' + id, data);
  }

  getGeoJSON(){
    return this.http.get(environment.endpoint + '/data/clubs.geojson');
  }

  runClubAction(id:string, action: string, data:any) {
    return this.http.post(environment.endpoint + '/clubs/' + id + '/' + action, data);
  }
}
