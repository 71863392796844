import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private http: HttpClient) { }

  getOrganizations() {
    return this.http.get(environment.endpoint + '/organizations');
  }

  getStandings(){

  }

  getPlayers(organization: string, limit: number = 4, skip: number = 0) {
    let filter = '?limit=' + limit + '&skip=' + skip;

    return this.http.get(environment.endpoint + '/organizations/' + organization + '/players'  + filter);
  }
}
