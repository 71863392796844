<li *ngIf="club" class="card animate__animated animate__fadeIn">
  <div class="banner" routerLink="/clubs/{{club._id}}">


    <span class="tag" *ngIf="club.distance"><i class="fa-solid fa-location-arrow"></i> {{club.distance | convert:'miles'}}</span>
    <img *ngIf="club.logo" src="{{club.logo}}"/>
    <img *ngIf="!club.logo && club.banner" src="{{club.banner}}"/>
    <span *ngIf="!club.banner && !club.logo" class="initials">{{club.tag}}</span>
    <span *ngIf="!club.active" class="warning"><i class="fa-solid fa-triangle-exclamation"></i> Inactive</span>
  </div>
  <div class="body">
    <a class="title" routerLink="/clubs/{{club._id}}">{{club.name}}</a>
    <span class="small"><i class="fa-solid fa-at"></i>{{club.tag}}</span>
    <ul class="metrics">
      <li>
        <i class="fa-solid {{focusMap[club.focus]}}"></i> {{club.focus}}
      </li>
      <li>
        <i class="fa-solid fa-circle-user"></i> {{club.members}}
      </li>
    </ul>
  </div>
</li>
