<div class="post-wrapper wide">
  <header>
    <div class="wrapper post-header">
      <div class="details">
        <h1>Terms and Conditions</h1>
        <span class="meta">
          <h2>By Using DartSweep, you agree to the terms below.</h2>
        </span>
      </div>
    </div>
  </header>
  <div class="content">
    <div class="post-body card">
      <ol>
        <li>To access our services, please confirm that you are at least 13 years old and meet the minimum age required by the laws of your country.</li>
        <li>If you are old enough to access our services in your country but not old enough to give consent to our terms, your parent or legal guardian agrees to our terms on your behalf. </li>
        <li>As a parent or legal guardian, if you allow your child (who meets the minimum age for your country) to use the services, these terms also apply to you. You will be responsible for overseeing your child's activities on the services, including any purchases made by them.</li>
        <li>We may need to suspend or terminate your account and/or access to DartSweep at any time and for any reason.</li>
        <li>Please be aware that your use of DartSweep is solely at your own risk. We provide the service "as is" and "as available," and we do not provide any warranties, either expressed or implied.</li>
        <li>You can terminate your account by sending an email request to legal@brandondiaz.me.</li>
        <li>We cannot be held responsible for any errors or instances of fraud that may occur during your use of the service. However, we are committed to addressing any issues that arise to the best of our abilities.</li>
        <li>If you choose to use payment gateways for managing or making payments, please make sure to adhere to their respective terms of service and privacy policies. Each payment gateway may have its own guidelines and rules.</li>
        <li>Please refrain from using DartSweep for any illegal or unauthorized purposes. It is important to comply with the laws of your jurisdiction, including copyright and trademark laws, while using DartSweep.</li>
      </ol>
    </div>
    <span class="pseudo-tab">Privacy Policy</span>
    <div class="post-body card">
      <ol>
        <li>We use a third-party analytics script to collect anonymous usage data for statistical purposes. This service cannot track individual users and collects no personal information or fingerprinting technologies.</li>
        <li>DartSweep includes links to external websites operated by third parties. It's important to note that we have no control over the content, privacy policies, or practices of these websites, products, or services. We assume no responsibility for them. Please exercise caution and review the policies of these third-party sites before engaging with them.</li>
        <li>Additionally we use the following third party services, and share the minimum required information with them in a secure manner:
          <ul>
            <li><b>SendGrid</b> receives your email address and name/handle for transactional and account-related emails as needed.</li>
            <li><b>Stripe</b> collects your payment details for secure payment processing. We do not store or collect any details related to your payment cards.</li>
            <li><b>MongoDB Atlas</b> stores all user and service data in am encrypted and secure fashion.</li>
            <li><b>Cloudflare</b> processes all traffic between users and DartSweep in an encrypted fashion.</li>
            <li><b>Amazon Web Services</b> processes all internal DartSweep traffic in an encrypted fashion.</li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
</div>
